<template>
  <div class="lasbalas-container">
    <!-- <v-responsive>
      <video preload="yes" autoplay loop muted playsinline class="main-video">
        <source src="/img/money/banner.webm" type="video/webm" />
        <source src="/img/money/banner.mp4" type="video/mp4" />
    </video>-->
    <v-img
      id="lasbalas-desktop-header"
      class="lasbalas-spacer align-end"
      src="/img/money/banner.gif"
    >
      <v-layout row justify-center class="lasbalas-header-container">
        <v-spacer />
        <v-flex xs10 class="black white--text">
          <v-layout justify-center align-center>
            <v-flex class="pa-4">
              <div class="lasbalas-title-container lasbalas-header title-font text-xs-center">
                <span class="lasbalas-header-text">¿Qué pasa antes de que</span>
                <v-spacer></v-spacer>
                <span class="lasbalas-header-text">escuches la canción?</span>
              </div>
            </v-flex>
            <div class="lasbalas-header-spacer my-3 black">
              <div class="spacer-bg"></div>
            </div>
            <v-flex xs6 class="px-5 py-3 text-body lasbalas-desc" v-html="info_header_description"></v-flex>
          </v-layout>
        </v-flex>
        <v-spacer />
      </v-layout>
    </v-img>
    <!-- </v-responsive> -->

    <LasBalasSeccionCamino class="lasbalas-camino" id="lasbalas-section-camino" />
    <LasBalasSeccionDestino class="lasbalas-destino" id="lasbalas-section-destino" />
    <v-img
      id="lasbalas-fueracuba"
      class="lasbalas-fueracuba panel"
      src="/img/money/info/last-info-bkg.jpg"
    >
      <v-layout row>
        <v-spacer />
        <v-flex xs10>
          <v-layout column class="money-content money-third-info">
            <span
              class="money-third-info-title-container text-uppercase title-font px-3 mt-4 pt-3 mb-2 pb-3"
            >
              ¿Qué pasa cuando el reguetón sale de Cuba?
              <!-- <span
                class="money-third-info-title text-uppercase"
              ></span>-->
            </span>
            <v-layout row class="money-third-info-content-container">
              <v-layout
                column
                justify-center
                class="money-left-arrow money-nav-arrow-position arrow-btn"
                v-if="!disable_prev_btn"
              >
                <v-btn icon absolute dark color="black" @click="handleMoneySlide('prev')">
                  <v-icon size="80">chevron_left</v-icon>
                </v-btn>
                <!-- <v-slide-y-transition>
                  <v-img src="/img/money/info/nav-arrows/left-arrow.png" :width="50"></v-img>
                </v-slide-y-transition>-->
              </v-layout>
              <v-layout
                row
                align-center
                class="money-third-info-content mx-4 black pt-5"
                :class="{'fueraCuba-info-paddingLeft': fueraCubaData.position === 'left'}"
                v-if="fueraCubaData.position === 'left'"
              >
                <v-spacer></v-spacer>
                <v-flex>
                  <v-img class="money-third-info-img-content" :src="fueraCubaData.src" width="520"></v-img>
                </v-flex>
                <v-flex
                  xs5
                  class="money-third-info-text-content px-5 mr-3 text-xs-left text-body white--text"
                  v-html="fueraCubaData.text"
                >
                  <!-- <p class="money-third-info-text fueraCuba-text-left"></p> -->
                </v-flex>
              </v-layout>
              <v-layout
                row
                align-center
                class="money-third-info-content mx-4 black pt-5"
                :class="{'fueraCuba-info-paddingRight': fueraCubaData.position === 'right'}"
                v-if="fueraCubaData.position === 'right'"
              >
                <v-flex
                  xs5
                  class="money-third-info-text-content px-5 ml-3 text-xs-right text-body white--text"
                  v-html="fueraCubaData.text"
                >
                  <!-- <p class="money-third-info-text fueraCuba-text-right" ></p> -->
                </v-flex>
                <v-flex>
                  <v-img class="money-third-info-img-content" :src="fueraCubaData.src" width="520"></v-img>
                </v-flex>
                <v-spacer></v-spacer>
              </v-layout>
              <v-layout
                column
                justify-center
                class="money-right-arrow money-nav-arrow-position arrow-btn"
                v-if="!disable_next_btn"
              >
                <v-btn @click="handleMoneySlide('next')" icon absolute dark color="black">
                  <v-icon size="80">chevron_right</v-icon>
                </v-btn>
                <!-- <v-img src="/img/money/info/nav-arrows/right-arrow.png" :width="50"></v-img> -->
              </v-layout>
            </v-layout>
          </v-layout>
        </v-flex>
        <v-spacer />
      </v-layout>
    </v-img>
  </div>
</template>

<script>
// import { TweenMax, Linear } from "gsap";
// import * as d3 from "d3";
import LasBalasSeccionCamino from "../../components/Money/LasBalasSeccionCamino";
import LasBalasSeccionDestino from "../../components/Money/LasBalasSeccionDestion";

export default {
  name: "MoneyDesktop",
  mounted() {
    // let sceneA = this.$scrollmagic
    //   .scene({
    //     triggerElement: "#lasbalas-section-camino",
    //     triggerHook: "0",
    //     duration: "100%"
    //   })
    //   .setPin("#lasbalas-section-camino", {
    //     pushFollowers: true,
    //     superClass: "lasbalas-camino-scrollmagin-pin"
    //   })
    //   .on("enter", e => {
    //     let el = d3.select(e.target.triggerElement());
    //     if (el !== undefined) {
    //       el.node().style.paddingTop = "55px";
    //     }
    //   })
    //   .on("leave", e => {
    //     let el = d3.select(e.target.triggerElement());
    //     if (el !== undefined) {
    //       el.node().style.paddingTop = "0";
    //     }
    //   });

    // let sceneB = this.$scrollmagic
    //   .scene({
    //     triggerElement: "#lasbalas-section-destino",
    //     triggerHook: "0",
    //     duration: "100%"
    //   })
    //   .setPin("#lasbalas-section-destino")
    //   .on("enter", e => {
    //     let el = d3.select(e.target.triggerElement());
    //     if (el !== undefined) {
    //       el.node().style.paddingTop = "42px";
    //     }
    //   })
    //   .on("leave", e => {
    //     let el = d3.select(e.target.triggerElement());
    //     if (el !== undefined) {
    //       el.node().style.paddingTop = "0";
    //     }
    //   });
    // this.$scrollmagic.addScene(sceneA);
    // this.$scrollmagic.addScene(sceneB);

    this.getCurrentThirdInfo();
  },
  data() {
    return {
      info_header_description:
        "Detrás de cada tema hay una industria con artistas, productores musicales y de video, distribuidores, e incluso, espacios de presentación. A veces legal, a veces ilegal, crece y mueve millones. Algunos de sus eslabones son imprescindibles, otros no; pero justamente en recorrer cada uno de sus pasos radica el éxito.",
      camino_corto_path: ["artistas", "estudio", "pkt"],
      camino_corto_info: null,
      destino_last_element: false,
      third_info_data: [
        {
          id: 1,
          src: "/img/money/info/content/content-1.jpeg",
          text:
            "El crecimiento económico del reguetón cubano es en gran medida, gracias a la simbiosis entre la Isla y su diáspora en Miami. El movimiento de artistas ocurre en ambos sentidos: los habitantes de la Isla graban (e incluso viven) en Miami y los de Miami graban en Cuba. En opinión de muchos de los entrevistados, para “pegarse” en Miami, es indispensable pegarse en Cuba.",
          position: "right"
        },
        {
          id: 2,
          src: "/img/money/info/content/content-2.jpeg",
          text:
            "Entre estudios de ambas orillas hay colaboraciones para producir una canción: en uno pudiera grabarse la voz, y en otro el background. En estudios de grabación como el de Vladimir Companioni, radicado en Estados Unidos, que ha trabajado con El Chacal, por ejemplo; la producción de una canción completa ronda los 2000 dólares.",
          position: "left"
        },
        {
          id: 3,
          src: "/img/money/info/content/content-3.jpeg",
          text:
            "En la expansión del género han sido importantes sellos discográficos extranjeros como Planet Records, con sedes en Europa, Miami, República Dominicana y Cuba, y Urban Latin Records, que incluyen en su catálogo algunas de las mayores estrellas del reguetón. La primera tiene una alianza reconocible con La Oficina Secreta, mientras la segunda se asocia con Célula Music. De estas relaciones sale gran parte del capital que financia las giras internacionales y los videos.",
          position: "right"
        },
        {
          id: 4,
          src: "/img/money/info/content/content-4.jpeg",
          text:
            "La prosperidad del reguetón cubano está profundamente ligada con el mercado extranjero, fundamentalmente Miami. Fuera de la Isla, se ha generado un circuito en el cual se presentan muchos artistas, como El Chacal, Diván o Yomil y el Dany, quienes copan las discotecas y estudios de Miami, y se han expandido también a otras urbes de Estados Unidos, Canadá o Europa.",
          position: "left"
        }
      ],
      next_info: 1,
      fueraCubaData: {},
      disable_prev_btn: true,
      disable_next_btn: false
    };
  },
  components: {
    LasBalasSeccionCamino,
    LasBalasSeccionDestino
  },
  computed: {
    currentCamino: {
      get() {
        return this.current_camino;
      },
      set(value) {
        this.currentCamino(value);
      }
    }
  },
  methods: {
    getCurrentThirdInfo() {
      for (let el of this.third_info_data) {
        if (el.id == this.next_info) {
          this.fueraCubaData = el;
          break;
        }
      }
    },
    handleMoneySlide(pos) {
      if (pos === "prev") this.next_info--;
      else if (pos === "next") this.next_info++;
      this.getCurrentThirdInfo();
      if (this.next_info == 1) {
        this.disable_prev_btn = true;
      }
      if (this.next_info > 1) {
        this.disable_prev_btn = false;
        this.disable_next_btn = false;
      }
      if (this.next_info == this.third_info_data.length) {
        this.disable_next_btn = true;
      }
    }
  }
};
</script>

<style>
.arrow-btn {
  /* width: 30px; */
  position: relative;
  z-index: 1;
}

.money-left-arrow > .v-btn {
  left: 0;
}

.money-right-arrow > .v-btn {
  right: 0;
}

.arrow-btn > .v-btn {
  /* position: absolute; */
  width: 80px;
  height: 80px;
  border-radius: 0;
  margin: 0;
}

.lasbalas-header {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.lasbalas-desc {
  font-size: 13px;
  letter-spacing: 1px;
  font-weight: bold;
}

.lasbalas-container {
  height: 100%;
  font-weight: normal;
  background-color: #f0f0ee;
  padding: 0;
  min-width: 100vw;
}

.lasbalas-container .lasbalas-camino-scrollmagin-pin {
  max-heigth: 694px !important;
}

section {
  display: flex;
  display: -webkit-flex;
}

/* .lasbalas-spacer {
        height: 100vh;
    } */

/* #lasbalas-desktop-header {
        background: url("/img/money/banner.gif") no-repeat;
        background-size: cover;
        align-items: flex-end;
        -webkit-align-items: flex-end;
    } */

/* .lasbalas-header-container {
        background-color: black;
        height: 10vw;
        max-width: 81vw;
        margin-left: 114px;
    } */

.lasbalas-header-container {
  position: absolute;
  bottom: 0;
}

.lasbalas-header-spacer {
  position: relative;
  /* background: url("/img/money/money-title-separator.png") no-repeat; */
  /* width: 2vw; */
  /* background-size: contain; */
  /* margin-top: 2vw; */
}

.lasbalas-header-spacer > .spacer-bg {
  position: absolute;
  width: 10px;
  background: linear-gradient(180deg, #f2defa, #7b8cf4, #ed65ed);
  height: 100%;
}

/* .lasbalas-header {
        width: 100vw;
        padding: 2vw 5vw;
    } */

/* .lasbalas-title-container {
        padding-left: 10vw;
        padding-top: 3vw;
        padding-right: 0;
    } */

.lasbalas-header-text {
  color: white;
}

/* .lasbalas-title {
        font-family: Andada-Bold, Roboto, sans-serif;
        text-transform: uppercase;
        font-size: 1vw;
        letter-spacing: 5px;
    } */

.lasbalas-text {
  font-size: 12px;
  text-transform: none;
  font-weight: 500;
}

#lasbalas-camino-info {
  align-items: normal;
  background: url("/img/money/banner-degradado.jpg") no-repeat;
  background-size: cover;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
}

.pinCaminoContainer {
  position: relative;
}

.lb-description-section-layout {
  align-self: normal;
  overflow: hidden;
  height: 500px;
  widhth: 100%;
  position: fixed;
  z-index: -1;
  visibility: visible;
}

.lb-img-container {
  position: relative;
  overflow: hidden;
}

.lb-camino-img {
  width: 45vw;
}

.lb-camino-description-text {
  background-color: white;
  width: 45vw;
  height: 480px;
  text-transform: initial;
  font-size: 11px;
  text-align: left;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 3vw 5vw 0;
  overflow: hidden;
  overflow-y: auto;
}

.lb-camino-description-text p {
  padding: 0;
  margin: 0;
}

/* #lasbalas-fueracuba {
        background: url("/img/money/info/last-info-bkg.jpg") no-repeat!important;
        background-size: cover!important;
        height: 42.6vw;
        align-items: flex-start;
        padding: 0 8vw 0 6.5vw;
    } */

.money-third-info {
  background-color: rgba(229, 220, 22, 0.75);
  /* height: 70vh; */
}

/* .money-third-info-title {
  color: black;
  letter-spacing: 14px;
  font-size: 1.2vw;
  font-family: Andada-Bold, Roboto, sans-serif;
  font-weight: lighter;
} */

.money-third-info-title-container {
  /* margin: 3vh 14vh; */
  font-size: 25px;
  letter-spacing: 10px;
  text-align: center;
}

/* .money-third-info-content {
  max-width: 100vw;
  min-width: 80.5vw;
  position: relative;
  left: -15px;
  background-color: black;
  padding-top: 1vw;
} */

/* .fueraCuba-info-paddingRight {
  padding-right: 4vw;
} */

/* .fueraCuba-info-paddingLeft {
  padding-left: 4vw;
} */

/* .fueraCuba-text-left {
  text-align: left;
}

.fueraCuba-text-right {
  text-align: right;
} */

.money-third-info-text-content {
  font-size: 13px;
  letter-spacing: 2px;
  font-weight: bold;
}
/* .money-third-info-text-content {
  background-color: black;
  width: 32vw;
  padding: 0 4vw;
  height: inherit;
  align-items: center;
  display: flex;
} */

/* .money-third-info-text {
  color: white;
  word-spacing: 2px;
  text-transform: initial;
  font-weight: lighter;
  font-size: 1rem;
} */

/* .money-third-info-img-content {
  align-self: flex-end;
} */

/* .money-nav-arrow-position {
  position: relative;
  top: 22vh;
  right: 0;
} */

/* .money-right-arrow {
  left: -2.2vw;
} */

/* .money-nav-arrow-position:hover {
  cursor: pointer;
} */

.change-visibility {
  visibility: hidden;
}
</style>
