<template>
  <v-container id="components-layout" ref="cmpLayout" v-scroll="scrollDown">
    <v-layout
      class="component-content"
      sticky-container
      :class="{'with-bg': sidebarCanciones}"
      v-if="!setSidebarPosicion"
    >
      <v-flex :xs10="sidebarCanciones" style="min-height: 500px;" ref="content">
        <v-layout column>
          <slot name="headerImage"></slot>
          <slot name="component"></slot>
        </v-layout>
      </v-flex>
      <v-flex xs2 ref="sidebarSizer" v-if="sidebarCanciones">
        <sidebar
          view_actions
          disabled_tooltip
          view_filters
          ref="sidebar"
          v-sticky
          sticky-offset="{top: 64}"
          color="black"
          small
        />
      </v-flex>
    </v-layout>
    <v-layout column v-if="setSidebarPosicion">
      <div ref="headerImage">
      <slot name="headerImage"></slot>
      </div>
      <v-layout>
        <slot name="component"></slot>
        <sidebar view_actions disabled_tooltip :view_filters="false" color="black"
        ref="sidebar" style="position: fixed; right: 0; width: 19vw;"/>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>
import CancionesSidebar from "./Canciones/CancionesSidebar";
export default {
  name: "",
  components: {
    sidebar: CancionesSidebar
  },
  props: {
    component: {
      type: String,
      default: ""
    }
  },
  computed: {
    sidebarCanciones() {
      if (this.component === "canciones") {
        return true;
      } else {
        return false;
      }
    },
    setSidebarPosicion() {
      if (this.component === "lasbalas") {
        return true;
      } else return false;
    }
  },
  // mounted () {
  //     window.addEventListener('scroll', this.scrollDown);
  // },
  // destroyed () {
  //     window.removeEventListener('scroll', this.scrollDown);
  // },
  data: () => ({}),
  updated() {
    this.updateSize();
  },
  mounted() {
    this.updateSize();
  },
  methods: {
    scrollDown() {
      // this is used to ensure that the filter sidebar has a sticky behavior inside
      // the space left by the song list
      const { sidebar, headerImage } = this.$refs;
      if (!sidebar) return;

      const sidebarEl = sidebar.$el;
      // if (content) {
      // const offset =
      //   e.target.scrollingElement.scrollTop -
      //   content.offsetHeight +
      //   sidebarEl.offsetHeight;
      // if (offset > 0) {
      //   sidebarEl.style.transform = `translateY(-${offset}px)`;
      // } else {
      //   sidebarEl.style.transform = null;
      // }
      // }

      if (headerImage) {
        const bottom = Math.max(headerImage.getBoundingClientRect().bottom, 64);
        sidebarEl.style.top = `${bottom}px`;
      }
      // const { sidebar } = this.$refs
      // const el_offsetHeight = sidebar.$el.offsetHeight
      // if (this.component === "canciones") {
      //   if (el_offsetHeight <= window.scrollY) {
      //       sidebar.$el.style.position = 'absolute';
      //   }
      //   else sidebar.$el.style.position = 'fixed';
      // }
      // else {
      //   if (cmpLayout.offsetHeight <= window.scrollY) {
      //       sidebar.$el.style.position = 'absolute';
      //   }
      //   else {
      //     sidebar.$el.style.position = 'fixed';
      //   }
      // }
    },
    updateSize() {
      // the flex containing the sidebar has the correct width, but the sidebar boxsize
      // is absolute so it can not resize acording to parent, this line gets the flex
      // computed width and uses it as the sidebar width.
      // const { sidebar, sidebarSizer } = this.$refs;
      // if (sidebar && sidebarSizer) {
      //   sidebar.$el.style.width = `${sidebarSizer.offsetWidth}px`;
      // }
    }
  }
};
</script>
<style lang="scss" scoped>
.component-content.with-bg {
  background-image: url("/img/canciones/lista-canciones-bkg.jpeg");
  background-size: cover;
}
#components-layout {
  padding: 0;
  margin: 0;
}

.setPosicion {
  top: 50px;
}
</style>
