<template>
    <v-flex class="money-info-text-container">
        <p class="money-info-text" v-html="cmpText"></p>
        <v-layout column class="money-numero-info" v-if="destino && show_number">
            <span class="money-numero">+ 1 648 500 CUC</span>
            <p class="money-numero-text">movió la industria del reguetón en 2018</p>
        </v-layout>
    </v-flex>
</template>

<script>
    export default {
        name: "MobileText",
        props: {
            destino: Boolean,
            show_number: {
                type: Boolean,
                default: true,
            },
            cmpText: {
              type: String,
              default: ''
            }
        }
    }
</script>

<style scoped>
    .money-info-text-container {
        background: url("/img/money/mobile/degradado-texto.jpg") repeat-y;
        height: auto;
        width: 81vw;
        overflow-y: visible;
        background-size: cover;
        padding: 16px;
    }

    .money-numero-info {
        color: black;
        text-align: center;
        border-top: solid 2px black;
    }

    .money-numero {
        font-family: Andada;
        font-size: 9vw;
        margin-top: 3vw;
    }

    .money-numero-text {
        font-family: Segoe;
        font-style: italic;
        font-size: 4vw;
        letter-spacing: 0;
        position: relative;
        bottom: 2vw;
    }

    .money-info-text {
        margin: 7vw 5vw;
        font-family: Segoe;
        font-size: 4vw;
    }
</style>
