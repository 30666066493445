<template>
  <div
    v-sticky
    sticky-offset="offset"
    class="layout column e-cmp-camino-toolbar"
    on-stick="onStick"
  >
    <v-layout row class="py-2 black mobile-camino text-xs-center">
      <v-flex @click="showElementCaminoInfo">
        <v-layout column align-center class="mobile-camino-icon-container">
          <v-img src="/img/money/icons/star.png" class="money-mobile-icon star">
            <span class="star"></span>
          </v-img>
          <div class="icon-name-container money-icon-name text-uppercase title-font">artista</div>
        </v-layout>
      </v-flex>

      <v-flex>
        <v-layout align-center class="line-container">
          <v-img src="/img/money/icons/lineas.png" class="money-mobile-icon-line"></v-img>
        </v-layout>
      </v-flex>

      <v-flex @click="showElementCaminoInfo">
        <v-layout column align-center class="mobile-camino-icon-container">
          <v-img src="/img/money/icons/records.png" class="money-mobile-icon records">
            <span class="records"></span>
          </v-img>
          <div
            class="icon-name-container money-icon-name text-uppercase title-font"
          >estudio de grabacion</div>
        </v-layout>
      </v-flex>

      <v-flex>
        <v-layout align-center class="line-container">
          <v-img src="/img/money/icons/lineas.png" class="money-mobile-icon-line"></v-img>
        </v-layout>
      </v-flex>

      <v-flex @click="showElementCaminoInfo">
        <v-layout
          column
          align-center
          class="mobile-camino-icon-container"
          :class="{'camino-corto-mask': active_camino_corto}"
        >
          <v-img src="/img/money/icons/camera.png" class="money-mobile-icon camera">
            <span class="camera"></span>
          </v-img>
          <div
            class="icon-name-container money-icon-name text-uppercase title-font"
          >realizadores audiovisuales</div>
        </v-layout>
      </v-flex>

      <v-flex>
        <v-layout
          align-center
          class="line-container"
          :class="{'camino-corto-mask': active_camino_corto}"
        >
          <v-img src="/img/money/icons/lineas.png" class="money-mobile-icon-line"></v-img>
        </v-layout>
      </v-flex>

      <v-flex @click="showElementCaminoInfo">
        <v-layout
          column
          align-center
          class="mobile-camino-icon-container"
          :class="{'camino-corto-mask': active_camino_corto}"
        >
          <v-img src="/img/money/icons/handshake.png" class="money-mobile-icon handshake">
            <span class="handshake"></span>
          </v-img>
          <div class="icon-name-container money-icon-name text-uppercase title-font">representante</div>
        </v-layout>
      </v-flex>

      <v-flex>
        <v-layout align-center class="line-container">
          <v-img src="/img/money/icons/lineas.png" class="money-mobile-icon-line"></v-img>
        </v-layout>
      </v-flex>

      <v-flex @click="showElementCaminoInfo">
        <v-layout column align-center class="mobile-camino-icon-container">
          <v-img src="/img/money/icons/pc.png" class="money-mobile-icon pc">
            <span class="pkt"></span>
          </v-img>
          <div class="icon-name-container money-icon-name text-uppercase title-font">paquete</div>
        </v-layout>
      </v-flex>

      <!-- <v-layout column class="mobile-camino-icon-container icon-mr">
        <a class="money-mobile-link" name="pkt" @click="showElementCaminoInfo">
          <v-img src="/img/money/icons/pc.png" class="money-mobile-icon pc">
            <span class="pkt"></span>
          </v-img>
        </a>
        <div class="icon-name-container">
          <span class="money-icon-name text-uppercase">paquete</span>
        </div>
      </v-layout>-->

      <v-flex>
        <v-layout align-center class="line-container">
          <v-img src="/img/money/icons/lineas.png" class="money-mobile-icon-line"></v-img>
        </v-layout>
      </v-flex>

      <v-flex @click="showElementCaminoInfo">
        <v-layout
          column
          align-center
          class="mobile-camino-icon-container"
          :class="{'camino-corto-mask': active_camino_corto}"
        >
          <v-img src="/img/money/icons/tv.png" class="money-mobile-icon tv">
            <span class="tv"></span>
          </v-img>
          <div
            class="icon-name-container money-icon-name text-uppercase title-font"
          >medios de comunicacion</div>
        </v-layout>
      </v-flex>

      <!-- <v-layout column class="mobile-camino-icon-container icon-mr">
        <a class="money-mobile-link" name="tv" @click="showElementCaminoInfo">
          <div :class="{'camino-corto-mask': active_camino_corto}">
            <v-img src="/img/money/icons/tv.png" class="money-mobile-icon tv">
              <span class="tv"></span>
            </v-img>
          </div>
        </a>
        <div class="icon-name-container">
          <span
            v-if="active_camino_largo"
            class="money-icon-name text-uppercase"
          >medios de comunicacion</span>
        </div>
      </v-layout>-->

      <v-flex>
        <v-layout
          align-center
          class="line-container"
          :class="{'camino-corto-mask': active_camino_corto}"
        >
          <v-img src="/img/money/icons/lineas.png" class="money-mobile-icon-line"></v-img>
        </v-layout>
      </v-flex>

      <v-flex @click="showElementCaminoInfo">
        <v-layout
          column
          align-center
          class="mobile-camino-icon-container"
          :class="{'camino-corto-mask': active_camino_corto}"
        >
          <v-img src="/img/money/icons/mic.png" class="money-mobile-icon mic">
            <span class="mic"></span>
          </v-img>
          <div class="icon-name-container money-icon-name text-uppercase title-font">conciertos</div>
        </v-layout>
      </v-flex>

      <!-- <v-layout column class="mobile-camino-icon-container">
        <a class="money-mobile-link" name="mic" @click="showElementCaminoInfo">
          <div :class="{'camino-corto-mask': active_camino_corto}">
            <v-img src="/img/money/icons/mic.png" class="money-mobile-icon mic">
              <span class="mic"></span>
            </v-img>
          </div>
        </a>
        <div class="icon-name-container conciertos-text">
          <span v-if="active_camino_largo" class="money-icon-name text-uppercase">conciertos</span>
        </div>
      </v-layout>-->
    </v-layout>

    <v-toolbar
      color="black"
      height="48"
      :scroll-off-screen="fixed"
      :scroll-threshold="0"
      class="path-toolbar"
      style="z-index: -1;"
    >
      <v-layout row justify-space-around class="camino-btn">
        <a class="money-mobile-link" @click="changeCamino('largo')">
          <v-img
            width="120"
            v-if="active_camino_largo"
            src="/img/money/caminos/camino-largo-btn-active.png"
          ></v-img>
          <v-img
            width="120"
            v-if="!active_camino_largo"
            src="/img/money/caminos/camino-largo-btn-disable.png"
          ></v-img>
          <!-- <div
            class="money-camino-info-btn"
            :class="{'active-camino-largo': active_camino_largo, 'disabled-camino-largo': !active_camino_largo}"
          ></div>-->
        </a>
        <a class="money-mobile-link" @click="changeCamino('corto')">
          <v-img
            width="120"
            v-if="active_camino_corto"
            src="/img/money/caminos/camino-corto-btn-active.png"
          ></v-img>
          <v-img
            width="120"
            v-if="!active_camino_corto"
            src="/img/money/caminos/camino-corto-btn-disable.png"
          ></v-img>
          <!-- <div
            class="money-camino-info-btn"
            :class="{'active-camino-corto': active_camino_corto, 'disabled-camino-corto': ! active_camino_corto}"
          ></div>-->
        </a>
      </v-layout>
    </v-toolbar>
  </div>
</template>

<script>
export default {
  name: "Camino",
  props: {
    emit_event: Boolean
  },
  data() {
    return {
      offset: { top: 64 },
      fixed: false,
      show_panel: true,
      active_camino_corto: false,
      active_camino_largo: true,
      touch_action: false,
      disabled_elements: ["camera", "handshake", "tv", "mic"]
    };
  },
  mounted() {
    if (this.$store.getters.getCurrentCamino === null) {
      this.$store.commit("setCurrentCamino", "largo");
    }
    this.changeCamino(this.$store.getters.getCurrentCamino);
  },
  methods: {
    onStick(state) {
      this.fixed = state.sticked;
    },
    changeCamino(camino) {
      if (camino === "corto") {
        this.active_camino_corto = true;
        this.active_camino_largo = false;
      } else {
        this.active_camino_largo = true;
        this.active_camino_corto = false;
      }
      this.$store.commit("setCurrentCamino", camino);
      this.$store.commit("setCurrentCaminoId", 1);
      // this.$store.dispatch('getCurrentCamino').then(() => {});
    },
    showElementCaminoInfo(event) {
      let children = event.target.children.item(0),
        className = children.classList.item(0);
      if (className != null) {
        if (
          this.active_camino_largo ||
          (this.active_camino_corto && !this.isDisabledElement(className))
        ) {
          this.goToCaminoInfo(className);
          if (!this.$store.getters.getViewCaminoInfo) {
            this.$store.commit("setViewCaminoInfo", true);
          }
        }
      }
    },
    goToCaminoInfo(className) {
      switch (className) {
        case "star":
          this.getInfo(1);
          break;
        case "records":
          this.getInfo(2);
          break;
        case "camera":
          this.getInfo(3);
          break;
        case "handshake":
          this.getInfo(4);
          break;
        case "pkt":
          this.getInfo(5);
          break;
        case "tv":
          this.getInfo(6);
          break;
        case "mic":
          this.getInfo(7);
          break;
      }
    },
    getInfo(id) {
      if (this.$store.getters.getCurrentCaminoId != id)
        this.$store.commit("setCurrentCaminoId", id);
      this.$store.dispatch("getCurrentCamino").then(() => {});
      this.$vuetify.goTo('#path-info', {duration: 0, offset: 157});
    },
    isDisabledElement(className) {
      for (let el of this.disabled_elements) {
        if (className === el) return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.mobile-camino-icon-container {
  /* position: relative; */
}
.mobile-camino-icon-container > .money-icon-name {
  /* position: absolute; */
  /* top: 100%; */
  font-size: 12px;
}

/* .e-cmp-camino-toolbar {
  top: 0;
  width: 81vw;
  position: -webkit-sticky;
  position: sticky;
  background-color: black;
} */

.mobile-camino {
  height: inherit;
  overflow-y: hidden;
  overflow-x: auto;
  z-index: 3;
  /* padding: 2vw 5vw 2vw 0; */
}

.mobile-camino::-webkit-scrollbar {
  display: none;
}

.money-mobile-link {
  text-decoration: none;
}

.money-icon-name {
  /* font-family: Andada-Bold, Roboto, sans-serif; */
  /* font-size: 2vw; */
  letter-spacing: 3px;
  color: white;
  /* display: inline-block; */
}

.mobile-camino-icon-container {
  /* min-width: 10vw; */
  /* height: 70px; */
  /* margin-right: 16px; */
  /* margin-left: 16px; */
  margin: 0 16px;
}

.money-mobile-icon {
  width: 50px;
  height: 50px;
  margin: 0 10px;
  /* margin: 3vw 0; */
}

.line-container {
  height: 50px;
  /* width: 20px; */
}

/* .records {
        width: 25px;
        margin-top: 4vw;
        position: relative;
        left: 3vw;
        height: 40px;
        bottom: 6px;
    } */

/* .handshake {
        width: 46px;
    } */

/* .pc {
        width: 42px;
    }

    .tv {
        width: 40px;
    } */

/* .records-text {
        top: -12px;
    }

    .conciertos-text {
        top: -8px
    } */

.money-mobile-icon-line {
  height: 3px;
  width: 20px;
  position: relative;
  /* top: 7vw; */
  /*margin-left: 10px;*/
  /*margin-right: 5px;*/
}

.icon-name-container {
  padding-top: 5px;
}

/* .icon-name-container {
  width: 30vw;
  text-align: center;
  position: relative;
  right: 10vw;
} */

/*.icon-mr {*/
/*margin-right: 8vw;*/
/*}*/

/* .camino-btn {
  margin-bottom: 24px;
} */

/* .representante {
  right: 8vw;
} */

/* .active-camino-corto {
  background: url("/img/money/caminos/camino-corto-btn-active.png") no-repeat;
  background-size: contain;
}

.disabled-camino-corto {
  background: url("/img/money/caminos/camino-corto-btn-disable.png") no-repeat;
  background-size: contain;
}

.active-camino-largo {
  background: url("/img/money/caminos/camino-largo-btn-active.png") no-repeat;
  background-size: contain;
}

.disabled-camino-largo {
  background: url("/img/money/caminos/camino-largo-btn-disable.png") no-repeat;
  background-size: contain;
} */

/* .money-camino-info-btn {
  width: 30vw;
  height: 9vw;
  margin-left: 4vw;
  margin-top: 6vw;
  position: relative;
  left: 6vw;
} */

.set-text-height {
  height: 20vw;
}

.touch-action {
  touch-action: none;
}

.camino-corto-mask {
  opacity: 0.3;
}
</style>
