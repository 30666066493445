<template>
  <v-layout column fill-height class="comments-list-container mr-4">
    <!-- <v-spacer class="comments-top-spacer"></v-spacer> -->
    <v-layout row class="comments-header" :class="{'comments_header_ml': comments_header_ml}">
      <!-- <v-spacer class="comments-header-spacer"></v-spacer> -->
      <v-flex
        offset-xs1
        class="py-2 comments-header-title comments-title text-uppercase title-font"
      >
        <span class="black white--text px-4 py-2">deja tu comentario</span>
      </v-flex>
    </v-layout>
    <!-- <v-layout row class="comments-layout-container"> -->
    <v-layout column fill-height id="comments-container" class="white">
      <v-flex
        class="comment-container-flex mt-3 px-4"
        v-for="(comment, i) in comments_list"
        :key="i"
        :index="i"
      >
        <div class="comment-box">
          <v-layout row align-center>
            <span class="nick-dot"></span>
            <span class="text-uppercase title-font nick ml-3">{{ comment.nickname }}</span>
          </v-layout>
          <div class="text-font font-italic comment-text" v-text="comment.content"></div>
        </div>
      </v-flex>
    </v-layout>
    <!-- <v-flex class="comments-sidebar" :class="{'btn-pt': !hide_comments_container}">
        <v-btn flat class="comments-up-down-btn" @click="hideCommentsContainer">
          <font-awesome-icon class="comments-up-down-icon" :icon="comments_icon" />
        </v-btn>
    </v-flex>-->
    <!-- </v-layout> -->
  </v-layout>
</template>

<script>
import axios from "axios";

export default {
  name: "ListaComentarios",
  props: {
    comments_header_ml: Boolean
  },
  data() {
    return {
      // hide_comments_container: null,
      // comments_icon: "angle-down",
      // scrollTop: 0,
      comments_list: null,
      category: "10"
    };
  },
  // mounted() {
  //   this.hide_comments_container = true;
  // },
  created() {
    axios
      .get(`https://rssblog.herokuapp.com/comment?category=${this.category}`)
      .then(response => {
        this.comments_list = response.data;
      });
  }
  // methods: {
  //   hideCommentsContainer() {
  //     let el = this.$el.getElementsByClassName("comments-container").item(0);
  //     this.hide_comments_container = !this.hide_comments_container;
  //     if (this.hide_comments_container) this.comments_icon = "angle-down";
  //     else this.comments_icon = "angle-up";
  //     if (this.scrollTop == 0) {
  //       this.scrollTop = window.scrollY;
  //     } else {
  //       el.scrollTop = 0;
  //     }
  //   }
  // }
};
</script>

<style scoped>
#comments-container {
  overflow-y: auto;
  height: 400px;
}

.nick {
  color: #f600c1;
  font-size: 18px;
}

.nick-dot {
  width: 16px;
  height: 16px;
  background-color: black;
  border-radius: 50%;
}

.comment-text {
  margin-left: 32px;
}

@media only screen and (max-width: 600px) {
  .comments-list-container {
    margin: 0!important;
  }

  .comments-header-title {
    font-size: 12px;
  }
}
/* .comments-top-spacer {
  height: 5vh;
}

.comments-list-container {
  background-color: white;
  height: auto;
}

.comment-container-flex {
  width: 85vw;
  background-color: transparent;
  margin-top: 4vh;
  padding-left: 10vw;
}

.hide-comments-container {
  height: 22vh;
  overflow: hidden;
  margin-bottom: 4vh;
}

.show-comments-container {
  height: 100vh;
  overflow-y: scroll;
  scrollbar-color: gray lightgray;
}

.show-comments-container::-webkit-scrollbar {
  display: none;
}

.comments-sidebar {
  padding: 0;
  max-width: 24vw;
}

.btn-pt {
  padding-top: 100vh;
}

.comments-up-down-btn {
  background-color: lightgrey;
  border-radius: unset;
  margin: 0;
  padding: 0;
}

.comments-up-down-icon {
  color: black;
  font-size: 8vw;
}

.comment-user-name {
  margin-left: 2vw;
  font-size: 5vw;
  letter-spacing: 4px;
  color: #f600c1;
}

.comment-icon {
  color: black;
  font-size: 2vw;
  margin-top: 1.4vh;
}

.comment-text {
  font-size: 1.1em;
  margin-left: 4vw;
  text-align: left;
}

@media only screen and (min-device-width: 0px) and (max-device-width: 740px) {
  .comments-list-container {
    margin-top: 8vw;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
  .hide-comments-container {
    height: 24vh;
  }
}

@media only screen and (device-width: 360px) and (device-height: 740px) {
  .hide-comments-container {
    height: 20vh;
  }
}

@media only screen and (min-device-width: 0) and (max-device-width: 320px) {
  .hide-comments-container {
    height: 25vh;
  }
}

@media only screen and (min-device-width: 1024px) and (max-device-width: 2048px) {
  .comments-list-container {
    width: 45.1vw;
  }

  .comments_header_ml {
    margin-left: 2vw !important;
  }

  .comments-header {
    width: 22vw;
    max-height: 8vh;
    margin-left: 6vw;
    margin-top: -8vh;
  }

  .comments-top-spacer {
    display: none;
  }

  .comments-header-title {
    padding: 2vh 2vw;
    height: 8vh;
  }

  .comments-layout-container {
    max-width: 45vw;
    height: 30vw;
  }

  .comments-container {
    width: inherit;
    padding: 0;
    margin-top: 0;
    height: 100%;
    overflow-y: scroll;
    scrollbar-color: gray lightgray;
  }

  .comment-container-flex {
    margin-top: 3vh;
    padding-left: 6vw;
  }

  .comment-user-name {
    font-size: 3vh;
    margin-left: 1vw;
  }

  .comment-icon {
    font-size: 1.8vh;
  }

  .comment-text {
    font-size: 0.8em;
    margin-left: 4vh;
    margin-top: 1vh;
    max-width: 23vw;
  }

  .comments-sidebar {
    display: none;
  }

  .hide-comments-container {
    margin-bottom: 0;
  }
} */
</style>
