<template>
    <v-layout column class="camino-info-container" id="path-info">
        <div class="camino-info-img">
            <transition name="camino-img">
                <v-img :src="$store.getters.getCurrentCaminoImg"></v-img>
            </transition>
        </div>
        <div class="camino-info-description">
            <p class="camino-info-presentation-text" v-html="$store.getters.getCurrentCaminoText"></p>
        </div>
        <MobileText :cmpText="$store.getters.getCurrentCaminoMainText"/>
    </v-layout>
</template>

<script>
    import MobileText from './MobileText'

    export default {
        name: "CaminoInfo",
        props: {
            full_width: Boolean
        },
        components: {
            MobileText
        }
    }
</script>

<style scoped>
    .camino-info-container {
        width: 81vw;
    }

    .camino-info-description {
        background-color: rgba(0, 0, 0, 0.65);
        height: auto;
        padding: 16px;
    }

    .camino-info-presentation-text {
        color: white;
        font-family: Segoe;
        margin: 0 4vw 0 1vw;
        font-weight: bold;
        font-size: 4vw;
        text-align: left;
    }

    /*.set-img-size .v-image__image {*/
    /*background-position-x: -10vw!important;*/
    /*background-position-y: center!important;*/
    /*}*/

    @media only screen and (min-device-width: 0px) and (max-device-width: 320px) {

    }

    @media only screen and (min-device-width: 360px) and (max-device-width: 370px) {

    }

    @media only screen and (min-device-width: 375px) and (max-device-width: 400px) {

    }

    @media only screen and (min-device-width: 414px) and (max-device-width: 740px) {

    }
</style>
