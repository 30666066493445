<template>
  <v-layout column class="third-info-header-container">
      <div class="container-background-color" style="width: 81vw">
        <v-img class="image-size" :src="fueraCubaImage"></v-img>
      </div>
      <div class="third-info-description-container container-background-color">
        <slot name="fueraCubaTitleSlot"></slot>
      </div>
      <div class="third-info-text-container container-background-color">
        <p>{{ fueraCubaText }}</p>
      </div>
  </v-layout>
</template>
<script>
export default {
  name: "TheThirdInfoContent",
  props: {
    dataIndex: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.getFueraCubaData(this.dataIndex)
  },
  data: () => ({
    fueraCubaData: [
        {
            id: 1,
            name: 'titulo-1',
            // img_larger: '/img/money/info/mobile/3.2.png',
            img_smaller: '/img/money/info/mobile/3.2.png',
            text: "El crecimiento económico del reguetón cubano es en gran medida, gracias a la simbiosis entre la Isla y su diáspora en Miami. El movimiento de artistas ocurre en ambos sentidos: los habitantes de la Isla graban (e incluso viven) en Miami y los de Miami graban en Cuba. En opinión de muchos de los entrevistados, para “pegarse” en Miami, es indispensable pegarse en Cuba."
        },{
            id: 2,
            name: 'titulo-2',
            // img_larger: '/img/money/info/mobile/3.4.png',
            img_smaller: '/img/money/info/mobile/3.4.png',
            text: "Entre estudios de ambas orillas hay colaboraciones para producir una canción: en uno pudiera grabarse la voz, y en otro el background. En estudios de grabación como el de Vladimir Companioni, radicado en Estados Unidos, que ha trabajado con El Chacal, por ejemplo; la producción de una canción completa ronda los 2000 dólares."
        },{
            id: 3,
            name: 'titulo-3',
            // img_larger: '/img/money/info/mobile/3.6.png',
            img_smaller: '/img/money/info/mobile/3.6.png',
            text: "En la expansión del género han sido importantes sellos discográficos extranjeros como Planet Records, con sedes en Europa, Miami, República Dominicana y Cuba, y Urban Latin Records, que incluyen en su catálogo algunas de las mayores estrellas del reguetón. La primera tiene una alianza reconocible con La Oficina Secreta, mientras la segunda se asocia con Célula Music. De estas relaciones sale gran parte del capital que financia las giras internacionales y los videos."
        },{
            id: 4,
            name: 'titulo-4',
            // img_larger: '/img/money/info/mobile/3.8.png',
            img_smaller: '/img/money/info/mobile/3.8.png',
            text: "La prosperidad del reguetón cubano está profundamente ligada con el mercado extranjero, fundamentalmente Miami. Fuera de la Isla, se ha generado un circuito en el cual se presentan muchos artistas, como El Chacal, Diván o Yomil y el Dany, quienes copan las discotecas y estudios de Miami, y se han expandido también a otras urbes de Estados Unidos, Canadá o Europa."
        },
    ],
    fueraCubaImage: '',
    fueraCubaText: ''
  }),
  methods: {
    getFueraCubaData (index) {
        this.fueraCubaImage = this.fueraCubaData[index].img_smaller
        this.fueraCubaText = this.fueraCubaData[index].text
    },
  }
}
</script>
<style lang="scss" scoped>
// .image-size {
//     height: auto;
//     position: relative;
//     right: 0;
//     left: 0;
//     width: 85vw;
// }

.third-info-description-container {
    position: relative;
    width: 100vw;
}

.third-info-text-container {
  width: 81vw;
  color: white;
  font-size: 16px;
  padding: 5vw;
}

.container-background-color {
    background-color: rgba(0, 0, 0, 0.90);
}
</style>
