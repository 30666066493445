<template>
  <div class="money-container-wrapper" ref="main_container" data-view>
    <MoneyMobile class="hidden-md-and-up" />
    <MoneyDesktop class="hidden-sm-and-down"></MoneyDesktop>
    <!-- <ComentariosModal v-if="$store.getters.showModal"><ComentariosForm></ComentariosForm></ComentariosModal> -->
    <v-layout row wrap class="pa-4 comentarios">
      <v-flex xs12 sm6>
        <ListaComentarios />
      </v-flex>
      <v-flex xs12 sm6 class="pt-4">
        <ComentariosForm />
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import MoneyDesktop from "./MoneyDesktop";
import MoneyMobile from "../../components/Money/MoneyMobile";
// import ComentariosModal from '../../components/Comentarios/ComentariosModal'
import ComentariosForm from "../../components/Comentarios/ComentariosForm";
import ListaComentarios from "../../components/Comentarios/ListaComentarios";

export default {
  head: {
    title: {
      inner: "Flow y poder",
      separator: " - ",
      complement: "Las Balas"
    },
    meta: [
      { p: "og:title", c: "Flow y poder - Las Balas" },
      {
        p: "og:description",
        c:
          "El reguetón generó una industria con artistas, productores musicales, realizadores de videos, distribuidores, canales para su consumo y circuitos de presentación. Y no solo en Cuba, también traspasó la frontera nacional. En la actualidad, parece consolidada y mueve millones de dólares anualmente."
      },
      {
        n: "description",
        c:
          "El reguetón generó una industria con artistas, productores musicales, realizadores de videos, distribuidores, canales para su consumo y circuitos de presentación. Y no solo en Cuba, también traspasó la frontera nacional. En la actualidad, parece consolidada y mueve millones de dólares anualmente."
      },
      { p: "og:url", c: "https://reggaeton.eltoque.com/las-balas" },
      { p: "og:image", c: "https://reggaeton.eltoque.com/img/money/portada.jpg" }
    ]
  },
  components: {
    MoneyDesktop,
    MoneyMobile,
    // ComentariosModal,
    ComentariosForm,
    ListaComentarios
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.commit("setShowToolbarTitle", true);
      vm.$store.commit("setShowComponentsInFooter", true);
      vm.$store.commit("setViewCancionesBox", true);
      vm.$store.commit("setViewArtistasBox", true);
      vm.$store.commit("setViewLasBalasBox", false);
      vm.$store.commit("setViewJuegoBox", true);
      next();
    });
  }
};
</script>
<style>
.money-container-wrapper {
  padding: 0;
  max-width: 100vw;
  margin: 0;
}

/* @media only screen and (min-device-width: 0) and (max-device-width: 740px) {
        .money-mobile {
            display: flex;
        }

        .money-desktop {
            display: none;
        }
    }

    @media (min-device-width: 1024px) {
        .money-mobile {
            display: none;
        }

        .money-desktop {
            display: block;
        }
    } */
</style>
