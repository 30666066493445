<template>
  <article class="scrolly-container" ref="container">
    <v-layout row>
      <v-spacer></v-spacer>
      <v-flex xs10>
        <v-layout row class="lasbalas-camino-container">
          <v-flex shrink class="black pa-4">
            <v-layout column align-center class="lasbalas-camino-btn ma-auto" style="width: 130px;">
              <a class="lasbalas-btn-link mb-2">
                <v-img
                  @click="changeCamino('camino_largo')"
                  :src="active_camino_largo ? '/img/money/caminos/camino-largo-btn-active.png' : '/img/money/caminos/camino-largo-btn-disable.png'"
                  width="130"
                ></v-img>
                <!-- <div
            class="lasbalas-camino-largo"
            :class="{'active-camino-largo': active_camino_largo, 'disabled-camino-largo': !active_camino_largo}"
            @click="changeCamino('camino_largo')"
                ></div>-->
              </a>
              <a class="lasbalas-btn-link mt-2">
                <v-img
                  @click="changeCamino('camino_corto')"
                  :src="active_camino_corto ? '/img/money/caminos/camino-corto-btn-active.png' : '/img/money/caminos/camino-corto-btn-disable.png'"
                  width="130"
                ></v-img>
                <!-- <div
            class="lasbalas-camino-corto"
            :class="{'active-camino-corto': active_camino_corto, 'disabled-camino-corto': ! active_camino_corto}"
            @click="changeCamino('camino_corto')"
                ></div>-->
              </a>
            </v-layout>
          </v-flex>
          <v-flex class="black py-4 pr-4">
            <v-layout
              row
              fill-height
              class="lasbalas-camino-desktop text-xs-center"
              :class="{'short-path': active_camino_corto}"
            >
              <v-flex @click="section = 0; scrollToSection()">
                <v-layout column align-center class="lasbalas-camino-item star-item">
                  <!-- <a flat class="lasbalas-camino-icon" @click="caminoGoToElement"> -->
                  <v-img
                    src="/img/money/icons/star.png"
                    name="artistas"
                    class="money-mobile-icon icon-star mx-3"
                    width="40"
                    :aspect-ratio="1"
                  ></v-img>
                  <!-- </a> -->
                  <div
                    class="lasbalas-camino-icon-name white--text title-font text-uppercase"
                  >artista</div>
                </v-layout>
              </v-flex>

              <v-layout align-center style="height: 40px;">
                <v-img
                  src="/img/money/icons/lineas-desktop.png"
                  height="3"
                  width="30"
                  class="lasbalas-camino-line"
                ></v-img>
              </v-layout>

              <v-flex @click="section = 1; scrollToSection()">
                <v-layout column align-center class="lasbalas-camino-item records-item">
                  <!-- <a class="lasbalas-camino-icon records-icon" @click="caminoGoToElement"> -->
                  <v-img
                    src="/img/money/icons/records.png"
                    name="estudio"
                    class="money-mobile-icon records mx-3"
                    width="40"
                    :aspect-ratio="1"
                  ></v-img>
                  <!-- </a> -->
                  <div
                    class="lasbalas-camino-icon-name records-text white--text title-font text-uppercase"
                  >estudio de grabacion</div>
                </v-layout>
              </v-flex>

              <v-layout align-center style="height: 40px;">
                <v-img
                  src="/img/money/icons/lineas-desktop.png"
                  height="3"
                  width="30"
                  class="lasbalas-camino-line"
                ></v-img>
              </v-layout>

              <v-flex class="long-path-item" @click="setLongPathSection(2)">
                <v-layout column align-center class="lasbalas-camino-item camera-item">
                  <!-- <a class="lasbalas-camino-icon" @click="caminoGoToElement"> -->
                  <!-- <div :class="{'desktop-camino-corto-mask': active_camino_corto}"> -->
                  <v-img
                    src="/img/money/icons/camera.png"
                    name="video"
                    class="money-mobile-icon camera mx-3"
                    width="40"
                    :aspect-ratio="1"
                  ></v-img>
                  <!-- </div> -->
                  <!-- </a> -->
                  <div
                    class="lasbalas-camino-icon-name white--text title-font text-uppercase"
                  >realizadores audiovisuales</div>
                </v-layout>
              </v-flex>

              <v-layout align-center style="height: 40px;">
                <v-img
                  src="/img/money/icons/lineas-desktop.png"
                  height="3"
                  width="30"
                  class="lasbalas-camino-line"
                ></v-img>
              </v-layout>

              <v-flex class="long-path-item" @click="setLongPathSection(3)">
                <v-layout column align-center class="lasbalas-camino-item icon-mr">
                  <!-- <a class="lasbalas-camino-icon" @click="caminoGoToElement"> -->
                  <!-- <div :class="{'desktop-camino-corto-mask': active_camino_corto}"> -->
                  <v-img
                    src="/img/money/icons/handshake.png"
                    name="agente"
                    class="money-mobile-icon handshake mx-3"
                    width="40"
                    :aspect-ratio="1"
                  ></v-img>
                  <!-- </div> -->
                  <!-- </a> -->
                  <div
                    class="lasbalas-camino-icon-name representante white--text title-font text-uppercase"
                  >representante</div>
                </v-layout>
              </v-flex>

              <v-layout align-center style="height: 40px;">
                <v-img
                  src="/img/money/icons/lineas-desktop.png"
                  height="3"
                  width="30"
                  class="lasbalas-camino-line"
                ></v-img>
              </v-layout>

              <v-flex @click="section = 4; scrollToSection()">
                <v-layout column align-center class="lasbalas-camino-item icon-mr">
                  <!-- <a class="lasbalas-camino-icon" @click="caminoGoToElement"> -->
                  <v-img
                    src="/img/money/icons/pc.png"
                    name="paquete"
                    class="money-mobile-icon pc mx-3"
                    width="40"
                    :aspect-ratio="1"
                  ></v-img>
                  <!-- </a> -->
                  <div
                    class="lasbalas-camino-icon-name white--text title-font text-uppercase"
                  >paquete</div>
                </v-layout>
              </v-flex>

              <v-layout align-center style="height: 40px;">
                <v-img
                  src="/img/money/icons/lineas-desktop.png"
                  height="3"
                  width="30"
                  class="lasbalas-camino-line"
                ></v-img>
              </v-layout>

              <v-flex class="long-path-item" @click="setLongPathSection(5)">
                <v-layout column align-center class="lasbalas-camino-item icon-mr">
                  <!-- <a class="lasbalas-camino-icon" @click="caminoGoToElement"> -->
                  <!-- <div :class="{'desktop-camino-corto-mask': active_camino_corto}"> -->
                  <v-img
                    src="/img/money/icons/tv.png"
                    name="medios"
                    class="money-mobile-icon tv mx-3"
                    width="40"
                    :aspect-ratio="1"
                  ></v-img>
                  <!-- </div> -->
                  <!-- </a> -->
                  <div
                    class="lasbalas-camino-icon-name white--text title-font text-uppercase"
                  >medios de comunicacion</div>
                </v-layout>
              </v-flex>

              <v-layout align-center style="height: 40px;">
                <v-img
                  src="/img/money/icons/lineas-desktop.png"
                  height="3"
                  width="30"
                  class="lasbalas-camino-line"
                ></v-img>
              </v-layout>

              <v-flex class="long-path-item" @click="setLongPathSection(6)">
                <v-layout column align-center class="lasbalas-camino-item conciertos-item">
                  <!-- <a class="lasbalas-camino-icon" @click="caminoGoToElement"> -->
                  <!-- <div :class="{'desktop-camino-corto-mask': active_camino_corto}"> -->
                  <v-img
                    src="/img/money/icons/mic.png"
                    name="conciertos"
                    class="money-mobile-icon mic mx-3"
                    width="40"
                    :aspect-ratio="1"
                  ></v-img>
                  <!-- </div> -->
                  <!-- </a> -->
                  <div
                    class="lasbalas-camino-icon-name conciertos-text white--text title-font text-uppercase"
                  >conciertos</div>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- <section id="scrolly"> -->
        <v-layout row id="scrolly" class="demowrap px-4 pb-5">
          <v-flex id="imagesContainer">
            <!-- <div class="img-panel conciertos" v-if="active_camino_largo"> -->
            <v-img
              v-if="section === 6"
              src="img/money/caminos-info/camino-conciertos.png"
              class="camino-img"
              height="400"
            />
            <!-- </div> -->

            <!-- <div class="img-panel medios" v-if="active_camino_largo"> -->
            <v-img
              v-if="section === 5"
              src="img/money/caminos-info/camino-medios.png"
              class="camino-img"
              height="400"
            />
            <!-- </div> -->

            <!-- <div class="img-panel paquete"> -->
            <v-img
              v-if="section === 4"
              src="img/money/caminos-info/camino-pkt.png"
              class="camino-img"
              height="400"
            />
            <!-- </div> -->

            <!-- <div class="img-panel agente" v-if="active_camino_largo"> -->
            <v-img
              v-if="section === 3"
              src="img/money/caminos-info/camino-representante.png"
              class="camino-img"
              height="400"
            />
            <!-- </div> -->

            <!-- <div class="img-panel video" v-if="active_camino_largo"> -->
            <v-img
              v-if="section === 2"
              src="img/money/caminos-info/camino-realizadores.png"
              class="camino-img"
              height="400"
            />
            <!-- </div> -->

            <!-- <div class="img-panel estudio"> -->
            <v-img
              v-if="section === 1"
              src="img/money/caminos-info/camino-estudio.png"
              class="camino-img"
              height="400"
            />
            <!-- </div> -->

            <!-- <div class="img-panel artistas"> -->
            <v-img
              v-if="section === 0"
              src="/img/money/caminos-info/camino-artistas.png"
              class="camino-img"
              height="400"
            />
            <!-- </div> -->
          </v-flex>
          <v-flex
            xs7
            id="scrollContainer"
            class="scrollContainer white text-body mt-4"
            ref="scrollContainer"
            style="max-height: 376px; padding: 60px;"
            v-bar
            v-scroll:#scroll-target="onScroll"
          >
            <!-- <vue-scroll :ops="ops" ref="vScrollContainer"> -->
            <div ref="scrollable" id="scroll-target">
              <div class="lasbalas-step" id="s0" v-html="getText(0)"></div>
              <div class="lasbalas-separator"></div>
              <div class="lasbalas-step" id="s1" v-html="getText(1)"></div>
              <div class="lasbalas-separator"></div>
              <div class="lasbalas-step" id="s2" v-html="getText(2)"></div>
              <div class="lasbalas-separator"></div>
              <div class="lasbalas-step" id="s3" v-html="getText(3)"></div>
              <div class="lasbalas-separator"></div>
              <div class="lasbalas-step" id="s4" v-html="getText(4)"></div>
              <div class="lasbalas-separator"></div>
              <div class="lasbalas-step" id="s5" v-html="getText(5)"></div>
              <div class="lasbalas-separator"></div>
              <div class="lasbalas-step" id="s6" v-html="getText(6)"></div>
            </div>
            <!-- <div class="step artistas-text">
                <p v-html="getText(0)"></p>
              </div>
              <div id="trigger1"></div>
              <div class="step estudio-text">
                <p></p>
              </div>
              <div id="trigger2" v-if="active_camino_largo"></div>
              <div class="step video-text" v-if="active_camino_largo">
                <p></p>
                <a
                  href="https://eltoque.com/asiel-babastro-el-regueton-es-el-soundtrack-de-cuba/ "
                >Asiel Babastro: El reguetón es el soundtrack de Cuba</a>
              </div>
              <div id="trigger3" v-if="active_camino_largo"></div>
              <div class="step representante-text" v-if="active_camino_largo">
                <p></p>
              </div>
              <div id="trigger4"></div>
              <div class="step paquete-text" :class="{'setPkt-height': active_camino_largo}">
                <p></p>
              </div>
              <div id="trigger5" v-if="active_camino_largo"></div>
              <div class="step medios-text" v-if="active_camino_largo">
                <p></p>
              </div>
              <div id="trigger6" v-if="active_camino_largo"></div>
              <div class="step conciertos-text" v-if="active_camino_largo">
                <p v-html="getText(6)"></p>
              </div>
            <div id="trigger7" v-if="active_camino_largo"></div>-->
            <!-- </vue-scroll> -->
          </v-flex>
        </v-layout>
        <!-- </section> -->
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </article>
</template>

<script>
// import { TimelineMax, Linear, TweenMax, SlowMo } from "gsap";
// import vuescroll from "vuescroll";
// import * as d3 from "d3";
// import * as scroll from "../../utils/scroll";

export default {
  name: "LasBalasSeccionCamino",
  components: {
    // vuescroll
  },
  data() {
    return {
      section: 0,
      caminoDatos: [],
      current_camino: "",
      active_camino_corto: false,
      active_camino_largo: true
      // ops: {
      //   vuescroll: {
      //     wheelScrollDuration: 900
      //   },
      //   scrollPanel: {
      //     speed: 1500
      //   },
      //   rail: {},
      //   bar: {
      //     background: "#000",
      //     keepShow: true
      //   }
      // }
    };
  },
  mounted() {
    // this.scroller.resize();
    // this.caminoDatos = this.$store.getters.getCaminoData;
    // // build scene
    // this.$scrollmagic.attachTo(this.$refs.scrollContainer);
    // const sceneText = this.$scrollmagic
    //   .scene({
    //     triggerElement: "#trigger1",
    //     triggerHook: 5,
    //     duration: "100%"
    //   })
    //   .setTween(
    //     TweenMax.to("#scrollContainer .artistas-text", 1, {
    //       opacity: 0,
    //       ease: Linear.easeInOut
    //     })
    //   );
    // this.$refs.scrollContainer.$scrollmagic.addScene(this.sceneImgArtistas());
    // this.$refs.scrollContainer.$scrollmagic.addScene(this.sceneImgEstudio());
    // this.$refs.scrollContainer.$scrollmagic.addScene(this.sceneImgVideo());
    // this.$refs.scrollContainer.$scrollmagic.addScene(this.sceneImgAgente());
    // this.$refs.scrollContainer.$scrollmagic.addScene(this.sceneImgPaquete());
    // this.$refs.scrollContainer.$scrollmagic.addScene(this.sceneImgMedios());
    // this.$refs.scrollContainer.$scrollmagic.addScene(this.sceneImgConciertos());
  },
  methods: {
    onResize() {
      if (this.scroller) {
        this.scroller.resize();
      }
    },
    onScroll(e) {
      const scroll = e.target.scrollTop;
      const seps = document.querySelectorAll(".lasbalas-separator");
      for (let i = 0; i < seps.length; i++) {
        if (scroll + 60 < seps[i].offsetTop) {
          this.section = i;
          return;
        }
      }
      this.section = 6;
    },
    scrollToSection() {
      const el = document.getElementById(`s${this.section}`);
      this.$vuetify.goTo(el.offsetTop, {
        duration: 0,
        container: "#scroll-target",
        offset: -10
      });
    },
    // caminoGoToElement(event) {
    //   let parent = event.target.offsetParent,
    //     name = parent.attributes.name.nodeValue;
    //   if (!parent.parentNode.classList.contains("desktop-camino-corto-mask")) {
    //     let el = undefined;
    //     switch (name) {
    //       case "artistas":
    //         el = d3.select(".artistas-text").node();
    //         this.$refs.vScrollContainer.scrollIntoView(el, 500);
    //         break;
    //       case "estudio":
    //         el = d3.select("#trigger1").node();
    //         this.$refs.vScrollContainer.scrollIntoView(el, 500);
    //         break;
    //       case "video":
    //         el = d3.select("#trigger2").node();
    //         this.$refs.vScrollContainer.scrollIntoView(el, 500);
    //         break;
    //       case "agente":
    //         el = d3.select("#trigger3").node();
    //         this.$refs.vScrollContainer.scrollIntoView(el, 500);
    //         break;
    //       case "paquete":
    //         el = d3.select("#trigger4").node();
    //         this.$refs.vScrollContainer.scrollIntoView(el, 500);
    //         break;
    //       case "medios":
    //         el = d3.select("#trigger5").node();
    //         this.$refs.vScrollContainer.scrollIntoView(el, 500);
    //         break;
    //       case "conciertos":
    //         el = d3.select("#trigger6").node();
    //         this.$refs.vScrollContainer.scrollIntoView(el, 500);
    //         break;
    //     }
    //   }
    // },
    changeCamino(camino) {
      if (camino === "camino_corto") {
        this.current_camino = "camino_corto";
        this.active_camino_corto = true;
        this.active_camino_largo = false;
      } else {
        this.current_camino = "camino_largo";
        this.active_camino_largo = true;
        this.active_camino_corto = false;
      }
      this.fixSection();
    },
    getText(index) {
      return this.$store.getters.getCaminoData[index].main_text;
    },
    nextSection() {
      const sec = this.section;
      if (this.active_camino_largo || sec === 0) {
        this.section = Math.min(sec + 1, 6);
        return;
      }

      if (sec === 1) {
        this.section == 4;
      }
    },
    prevSection() {
      const sec = this.section;
      if (this.active_camino_largo || sec === 1) {
        this.section = Math.max(sec - 1, 0);
        return;
      }

      if (sec === 4) {
        this.section == 1;
      }
    },
    fixSection() {
      if (!this.active_camino_corto) return;
      switch (this.section) {
        case 2:
        case 3:
          this.section = 1;
          return;
        case 5:
        case 6:
          this.section = 4;
          return;
      }
    },
    setLongPathSection(section) {
      if (this.active_camino_corto) return;
      this.section = section;
      this.scrollToSection();
    }
    // sceneImgArtistas() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#trigger1",
    //       triggerHook: "onEnter",
    //       duration: "100%"
    //     })
    //     .setTween(
    //       TweenMax.to("#imagesContainer .artistas", 5, {
    //         scale: (0, 0),
    //         opacity: 0,
    //         ease: Linear.easeInOut
    //       })
    //     )
    //     .on("enter", () => {
    //       let el = d3.select(".estudio-text");
    //       el.select("p").html(this.getText(1));
    //     });
    // },
    // sceneImgEstudio() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#trigger2",
    //       triggerHook: "onEnter",
    //       duration: "100%"
    //     })
    //     .setTween(
    //       TweenMax.to("#imagesContainer .estudio", 1, {
    //         scale: (0, 0),
    //         opacity: 0,
    //         ease: SlowMo.ease.config(0.5, 0.8, false)
    //       })
    //     )
    //     .on("enter", () => {
    //       let el = d3.select(".video-text");
    //       el.select("p").html(this.getText(2));
    //     });
    // },
    // sceneImgVideo() {
    //   if (this.active_camino_largo) {
    //     return this.$scrollmagic
    //       .scene({
    //         triggerElement: "#trigger3",
    //         triggerHook: "onEnter",
    //         duration: "100%"
    //       })
    //       .setTween(
    //         TweenMax.to("#imagesContainer .video", 1, {
    //           scale: (0, 0),
    //           opacity: 0,
    //           ease: SlowMo.ease.config(0.5, 0.8, false)
    //         })
    //       )
    //       .on("enter", () => {
    //         let el = d3.select(".representante-text");
    //         el.select("p").html(this.getText(3));
    //       });
    //   }
    // },
    // sceneImgAgente() {
    //   if (this.active_camino_largo) {
    //     return this.$scrollmagic
    //       .scene({
    //         triggerElement: "#trigger4",
    //         triggerHook: "onEnter",
    //         duration: "75%"
    //       })
    //       .setTween(
    //         TweenMax.to("#imagesContainer .agente", 1, {
    //           scale: (0, 0),
    //           opacity: 0,
    //           ease: SlowMo.ease.config(0.5, 0.8, false)
    //         })
    //       )
    //       .on("enter", () => {
    //         let el = d3.select(".paquete-text");
    //         el.select("p").html(this.getText(4));
    //       });
    //   }
    // },
    // sceneImgPaquete() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#trigger5",
    //       triggerHook: 1,
    //       duration: 300
    //     })
    //     .setTween(
    //       TweenMax.to("#imagesContainer .paquete", 1, {
    //         scale: (0, 0),
    //         opacity: 0,
    //         ease: SlowMo.ease.config(0.5, 0.8, false)
    //       })
    //     )
    //     .on("enter", () => {
    //       let el = d3.select(".medios-text");
    //       el.select("p").html(this.getText(5));
    //     });
    // },
    // sceneImgMedios() {
    //   if (this.active_camino_largo) {
    //     return this.$scrollmagic
    //       .scene({
    //         triggerElement: "#trigger6",
    //         triggerHook: "onEnter",
    //         duration: "100%"
    //       })
    //       .setTween(
    //         TweenMax.to("#imagesContainer .medios", 1, {
    //           scale: (0, 0),
    //           opacity: 0,
    //           ease: SlowMo.ease.config(0.5, 0.8, false)
    //         })
    //       );
    //   }
    // },
    // sceneImgConciertos() {
    //   if (this.active_camino_largo) {
    //     return this.$scrollmagic
    //       .scene({
    //         triggerElement: "#trigger7",
    //         triggerHook: "onEnter",
    //         duration: 100
    //       })
    //       .on("enter", e => {
    //         if (this.$refs.vScrollContainer !== undefined) {
    //           d3.select("#lasbalas-section-destino")
    //             .node()
    //             .scrollIntoView();
    //         }
    //       });
    //   }
    // },
  }
};
</script>

<style scoped>
.lasbalas-separator {
  height: 3px;
  width: 70%;
  background-color: black;
  margin: 60px auto;
}

.lasbalas-step {
  min-height: 100%;
}

#scrolly {
  /* position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 0 0 6.5vw; */
  /*background-color: #f3f3f3;*/
  background: url("/img/money/money-camino-bkg.jpg") no-repeat;
  background-size: cover;
  /* padding: 1rem;
  max-width: 85vw; */
}
/* #scrolly > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
} */

.camino-img {
  /* width: 100%; */
  height: 100%;
}

article.scrolly-container {
  background: linear-gradient(0deg, #f083f0, #9fbbfa);
  /* background: url("/img/money/banner-degradado.jpg") no-repeat; */
  /* background-size: cover; */
  /* height: 100vh; */
}

/* .demowrap {
  height: 500px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  margin-top: 50px;
} */

/* #imagesContainer {
  position: relative;
  margin: auto;
} */

.img-panel {
  left: -18.4vw;
  top: -314px;
  position: absolute;
}

.img-panel img {
  height: 500px;
}

/* .scrollContainer {
  width: 40vw;
  height: 480px;
  background-color: white;
  margin: 0 5px 7.8vw 5vw;
} */

.step {
  padding: 6vw 5vw 1vw;
  margin-bottom: 4vw;
}

.lasbalas-camino-container {
  /* max-width: 85vw; */
  /* height: 8.5vw; */
  /* background-color: black; */
  /* position: relative; */
  /* left: 6.5vw; */
}

.lasbalas-camino-desktop {
  font-size: 11px;
  letter-spacing: 3px;
}
.lasbalas-camino-desktop .lasbalas-camino-icon-name {
  font-weight: 400;
}

/* .lasbalas-camino-desktop {
  padding-top: 3.8vw;
  align-self: flex-start;
  justify-content: center;
  text-align: center;
  width: 70vw;
} */

/* .lasbalas-camino-btn {
  width: 12vw;
} */

/* .lasbalas-btn-link {
  text-decoration: none;
} */

/* .lasbalas-camino-corto,
.lasbalas-camino-largo {
  height: 2vw;
  margin: 1vw 2vw;
} */

/* .lasbalas-camino-largo {
  margin-top: 2vw;
} */

.lasbalas-camino-item {
  position: relative;
}

.lasbalas-camino-item > .lasbalas-camino-icon-name {
  position: absolute;
  top: 100%;
  margin-top: 5px;
}

.short-path .long-path-item .money-mobile-icon {
  opacity: 0.3;
}

.short-path .long-path-item .lasbalas-camino-icon-name {
  opacity: 0;
}

/* .lasbalas-camino-item {
  height: auto;
  max-width: 5vw;
  position: relative;
  bottom: 12px;
  align-items: center;
} */

/* .camera-item,
.star-item {
  bottom: 2vw;
} */

/* .records-item {
  bottom: 40px;
} */

/* .conciertos-item {
  bottom: 1vw;
} */

/* .lasbalas-camino-icon {
  width: 45px;
  height: auto;
  margin: 0 auto;
} */

.records-icon {
  width: 35px;
  /* left: 12px; */
  position: relative;
  top: 6px;
}

/* .lasbalas-camino-icon-name {
  color: white;
  font-size: 8px;
  letter-spacing: 2px;
  font-family: Andada-Bold, Roboto, sans-serif;
  position: relative;
  top: 6px;
} */

/* .lasbalas-camino-line {
  max-width: 5vw;
  height: 5px;
  margin-right: 1vw;
} */

/* .active-camino-corto {
  background: url("/img/money/caminos/camino-corto-btn-active.png") no-repeat;
  background-size: contain;
} */

/* .disabled-camino-corto {
  background: url("/img/money/caminos/camino-corto-btn-disable.png") no-repeat;
  background-size: contain;
} */

/* .active-camino-largo {
  background: url("/img/money/caminos/camino-largo-btn-active.png") no-repeat;
  background-size: contain;
} */

/* .disabled-camino-largo {
  background: url("/img/money/caminos/camino-largo-btn-disable.png") no-repeat;
  background-size: contain;
} */

#trigger7 {
  margin-bottom: 10vw;
}

#trigger1,
#trigger2,
#trigger3,
#trigger4,
#trigger5,
#trigger6 {
  border-bottom: solid 2px black;
  margin: 0 5vw;
}

.desktop-camino-corto-mask {
  opacity: 0.3;
  cursor: default;
}

.setPkt-height {
  height: 450px;
}
</style>
