<template>
    <v-layout class="destino-info-wrapper" column>
        <v-flex class="destino-info-presentation">
            <v-layout column class="destino-info-header" id="second">
                <h1 class="destino-info-title text-uppercase title-font">
                    ¿A dónde va tu dinero?
                </h1>
                <v-divider class="destino-header-divider"></v-divider>
                <div class="destino-info-description">
                  <p>
                    Todo el dinero que mueve la industria, en última instancia, es pagado por los consumidores. Los fanes
                    del reguetón pagan, por diferentes vías, gran cantidad de dinero y que no es únicamente para el artista,
                    muchos se benefician de ello.
                  </p>
                </div>
            </v-layout>
        </v-flex>
        <v-layout column class="destino-info-container">
          <destino-info-content v-for="i in 4" :key='i' :destinoDataIndex='i'/>
        </v-layout>
    </v-layout>
</template>

<script>
  import DestinoInfoContent from './DestinoInfoContent'

    export default {
        name: "DestinoInfo",
        components: {
            "destino-info-content": DestinoInfoContent
        },
        data() {
          return {

          }
        }
    }
</script>

<style scoped>
    .destino-info-wrapper {
        height: auto;
        background: url("/img/money/mobile/degradado-texto.jpg") no-repeat fixed;
        background-size: cover;
    }

    .destino-info-presentation {
        background: url("/img/money/mobile/destino-bkg.jpg") no-repeat fixed;
        background-size: cover;
        align-items: flex-end;
        -webkit-align-items: flex-end;
        width: 81vw;
        /* margin-bottom: 10vh; */
    }

    .destino-info-description {
      color: white;
      padding: 5vw 10vw;
    }

    .destino-info-bkg {
        background: url("/img/money/mobile/destino-bkg.jpg") repeat-y;
        background-size: cover;
        width: 85vw;
        height: 85vh;
        position: fixed;
    }

    .destino-info-header {
        background-color: black;
        /* position: relative; */
        /* width: 90vw; */
        /* left: 10vw; */
        bottom: 0;
        /* height: 50vh; */
        /* top: 50vh; */
        width: 100%;
        margin-top: 300px;
        /* margin-left: 60px; */
        color: white;
    }

    .destino-info-title {
        letter-spacing: 6px;
        font-size: 4vw;
        margin: 10vw;
        line-height: 1.8;
        /* padding-right: 20vw; */
    }

    .destino-header-divider {
        background: url("/img/money/mobile/degradado-barra.jpg") no-repeat;
        padding-top: 2vw;
        margin: 0 8vw;
        background-size: cover;
    }

    @media only screen and (min-device-width: 0px) and (max-device-width: 320px) {
        /*.destino-info-wrapper {*/
            /*max-height: 570px;*/
        /*}*/
    }

    @media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
        /*.destino-info-wrapper {*/
            /*max-height: 100vh;*/
        /*}*/
    }

</style>
