<template>
    <v-layout column class="third-info-wrapper">
      <div id="title" class="title-font text-uppercase pa-3">
          ¿Qué pasa cuando el reguetón sale de Cuba?
      </div>
      <fuera-cuba :data-index='0'>
        <template v-slot:fueraCubaTitleSlot>
          <div class="third-info-title-container">
              <v-layout class="title-1" column>
                  <v-layout column class="title-top-element">
                      <span class="text-uppercase">primero</span>
                      <span class="text-uppercase">hay que</span>
                  </v-layout>
                  <v-layout column class="title-bottom-element title-box-bkg">
                      <span class="text-uppercase">triunfar</span>
                      <span class="title-bottom-ls text-uppercase">en cuba</span>
                  </v-layout>
                  <div class="bottom-gradient"></div>
              </v-layout>
            </div>
        </template>
      </fuera-cuba>
      <fuera-cuba :data-index='1'>
        <template v-slot:fueraCubaTitleSlot>
          <div class="third-info-title-container">
            <v-layout class="title-2" column>
                <span class="text-uppercase title-text-color">pertenecer</span>
                <span class="text-uppercase title-text-color">a un</span>
                <span class="text-uppercase title-text-color">sello</span>
                <span class="title-box-bkg title-2-bottom text-uppercase">discográfico</span>
            </v-layout>
          </div>
        </template>
      </fuera-cuba>
      <fuera-cuba :data-index='2'>
        <template v-slot:fueraCubaTitleSlot>
          <div class="third-info-title-container">
            <v-layout class="title-3" column>
                <span class="title-3-span text-uppercase">cola-</span>
                <span class="title-3-span text-uppercase">bora-</span>
                <span class="text-uppercase title-box-bkg title-3-bottom">ción</span>
            </v-layout>
          </div>
        </template>
      </fuera-cuba>
      <fuera-cuba :data-index='3'>
        <template v-slot:fueraCubaTitleSlot>
          <div class="third-info-title-container">
            <v-layout class="title-4" column>
                <span class="title-text-color title-4-span text-uppercase">circuito--</span>
                <span class="title-text-color title-4-span text-uppercase">fuera</span>
                <span class="text-uppercase title-box-bkg title-4-bottom">de la isla</span>
            </v-layout>
          </div>
        </template>
      </fuera-cuba>
    </v-layout>
</template>

<script>
    import TheThirdInfoContent from './TheThirdInfoContent'

    export default {
        name: "ThirdInfo",
        components: {
          "fuera-cuba": TheThirdInfoContent
        }
    }
</script>

<style scoped>
#title {
    background-color: #e5dc16;
    width: 81vw;
    letter-spacing: 3px;
    font-size: 18px;
    text-align: right;
}

.third-info-wrapper {
    background: url("/img/money/mobile/third-info-bkg.jpg") no-repeat fixed;
    background-size: cover;
    height: auto;
}
    .description-height {
        height: 38vh;
    }

    .description-smaller-height {
        height: 45vh;
        margin-left: 5vw;
    }

    .third-info-title-container {
        height: inherit;
        font-family: Andada;
        font-weight: 900;
        font-size: 5.5vw;
        width: 100vw;
    }

    .title-top-element {
        color: white;
        letter-spacing: 7.5vw;
        padding: 5vw 12vw 0;
        line-height: 35px;
    }

    .title-box-bkg {
        background-color: #e5dc16;
    }

    .title-text-color {
        color: #e5dc16;
    }

    .title-bottom-element {
        padding: 2vw 0 2vw 12vw;
        margin-top: 3vw;
        margin-right: 15vw;
        font-style: italic;
        letter-spacing: 6vw;
    }

    .title-bottom-ls {
        letter-spacing: 7.1vw;
    }

    .bottom-gradient  {
        position: relative;
        background: url("/img/money/mobile/degradado-barra.jpg") no-repeat;
        height: 6vw;
        width: 35vw;
        background-size: cover;
        left: 45vw;
    }

    .title-2 {
        letter-spacing: 5vw;
        padding: 5vw;
        line-height: 11vw;
    }

    .title-2-bottom {
        letter-spacing: 4vw;
        margin-left: -5vw;
        margin-top: 3vw;
        padding-left: 5vw;
        line-height: 1.2;
    }

    .title-3 {
        line-height: 2.5;
        padding-top: 5vw;
        letter-spacing: 13vw;
        font-size: 7vw;
    }

    .title-3-span {
        color: white;
        margin-left: 10vw;
    }

    .title-3-bottom {
        padding-left: 10vw;
        line-height: 1.5;
        width: 85vw;
    }

    .title-4 {
        padding-top: 8vw;
        line-height: 2.5;
        font-size: 6vw;
        letter-spacing: 5.5vw;
    }

    .title-4-span {
        margin-left: 5vw;
    }

    .title-4-bottom {
        padding-left: 5vw;
        line-height: 1.5;
        width: 85vw;
        letter-spacing: 17px;
    }

    @media only screen and (min-device-width: 0) and (max-device-width: 320px) {
        /*.third-info-header-container {*/
            /*max-height: 50vh;*/
        /*}*/
    }

    @media only screen and (min-device-width: 360px) and (max-device-width: 740px) {
        /*.third-info-header-container {*/
            /*max-height: 46vh;*/
        /*}*/
    }
</style>
