<template>
  <div class="destino-img-container">
    <v-img :src="destinoDataImage" class="destino-info-img"></v-img>
    <div class="destino-img-description-container">
      <mobile-text destino :cmpText="destinoDataText" :show_number="destinoDataIndex === 4"/>
    </div>
  </div>
</template>
<script>
import MobileText from './MobileText'
export default {
  name: "",
  components: {
    "mobile-text": MobileText
  },
  props: {
    destinoDataIndex: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.getDestinoImage();
  },
  data: () => ({
    destinoDataText: "",
    destinoDataImage: "",
    destinoData: [
      {
          id: 1,
          src: '/img/money/destino/mobile/2.2.jpg',
          text: 'Aunque para escuchar una canción de reguetón en Cuba no es necesario comprarla, de tu bolsillo sí sale el dinero que alimenta la industria. Por ejemplo, pagas por el Paquete semanal y ahí encuentras la música actual y los éxitos más antiguos, aunque no se pueden calcular con exactitud sus ingresos.'+
          '<br><br>También puedes escucharla, o verla, en los medios de comunicación tradicionales como la televisión y la radio. Esto último no lo pagas directamente, pero algunas plataformas como Youtube generan pagos por vistas y les da visibilidad a los artistas, clave para alcanzar la popularidad. Lo que te lleva a la principal forma de pago: los conciertos.'+
          '<br><br>Mientras más “pegao” esté el artista, de su fama y del lugar, más caro vale un concierto. Solo en 2018 nuestro equipo contabilizó 109 conciertos de 11 artistas o grupos en 10 centros nocturnos estatales y privados en La Habana y 2 más en Cienfuegos y Pinar del Río con una recaudación mínima estimada, según el precio de entrada y la capacidad del lugar, de más de 723 500 CUC. Y eso solo en Cuba.'
      },
      {
          id: 2,
          src: '/img/money/destino/mobile/2.4.jpg',
          text: 'Para que un artista en Cuba pueda dar un concierto previamente debe concertarse un contrato entre el centro cultural y la agencia que representa al artista. Es un procedimiento requerido, de lo contrario las presentaciones son ilegales o tienen que hacerse por invitaciones a través de terceros o como artistas aficionados. Por esta intermediación la agencia de representación puede cobrar hasta un 30% del valor acordado.'+
          '<br><br>Por tanto, la ganancia del artista varía según los distintos mecanismos; los más establecidos suelen presentarse por un precio fijo previamente pactado o según los ingresos obtenidos en taquilla.'+
          '<br><br>Con respecto a los medios de comunicación, son muy pocos aún los que reciben dinero desde plataformas de Internet como Youtube, sobre todo porque para el contexto cubano eso es muy difícil y es necesario acceder a alguna cuenta fuera del país que pueda recibir el dinero; pero algunos lo han conseguido.'
      },
      {
          id: 3,
          src: '/img/money/destino/mobile/2.6.jpg',
          text: 'Del dinero recibido por el artista debe pagarle a su equipo de trabajo y cumplir con los impuestos sobre los ingresos personales que se calculan a partir de los ingresos obtenidos, las retenciones y los gastos incurridos en la actividad artística hasta 50%.'+
          '<br><br>Además, deben pagar la producción de nuevas canciones si quieren mantenerse en la escena, el ciclo nunca para.'+
          '<br><br>Para producir una canción los estudios cobran dependiendo de su posición, su legitimidad y el tipo de música que graben. Los precios oscilan entre 3 y 400 CUC por canción, aunque pudiera ser más si intervienen estudios extranjeros.'+
          '<br><br>Según datos de los estudios, se graban entre 10 y 12 canciones semanales. Calculando a 10 canciones semanales, son más de 500 canciones anuales producidas. Se identificaron al menos 40 estudios que graban reguetón cubano. De ellos 10 de reguetón y 15 de reparto. Si calculamos al precio mínimo en cada caso de 5 CUC y 80 CUC  tenemos un estimado de 437 500 CUC en solo un año.'+
          '<br><br>Además, las canciones necesitan de un video clip para llegar a más personas y dependiendo de la complejidad de la producción los precios varían desde 80 a 900 CUC en las canciones de Reparto y entre 150 y 10 000 CUC en las canciones de Farándula.'+
          '<br><br>Solo siete artistas durante 2018 (Divan, Yomil y el Dany, El Taiger, Dana, Alex Duval, la señorita Dayana y Jacob Forever) tienen 23 videos clip, calculado a 5 000 CUC y 10 000 para Yomil y el Dany, según sus propias declaraciones nos da un mínimo de 125 000 CUC.'
      },
      {
          id: 4,
          src: '/img/money/destino/mobile/2.8.jpg',
          text: 'Por último, las canciones y los videos llegan al Paquete y a los medios de comunicación donde pueden ser consumidas por los fanes.'+
          '<br><br>Son los propios estudios y muchas veces los realizadores y los promotores (que pueden ser parte del estudio o contratados de forma independiente por el artista) quienes se ocupan de la promoción y distribución de la música.'+
          '<br><br>Este paso, aunque no tiene altos costos, es esencial para lograr una buena relación entre el artista y el público.'+
          '<br><br>Y así comienza nuevamente el ciclo por donde te llega la música.'
      }
    ]
  }),
  methods: {
    getDestinoImage () {
      let index = this.destinoDataIndex - 1;
      this.destinoDataImage = this.destinoData[index].src
      this.destinoDataText = this.destinoData[index].text
    },
  }
}
</script>
<style lang="scss" scoped>
.destino-img-container {
  background: url("/img/money/mobile/destino-bkg.jpg") no-repeat fixed;
  background-size: cover;
  height: 100%;
  width: 100vw;
}

.destino-info-img {
    max-width: 81vw;
    height: 100%;
}

.destino-info-img .v-image__image {
    background-size: contain;
    background-position: left;
}
</style>
