<template>
  <cmp-layout component="lasbalas" v-scroll="onScroll">
    <template v-slot:headerImage>
      <!-- <video preload="yes" autoplay loop muted playsinline class="main-video">
        <source src="/img/money/BANNER-PHONE.webm" type="video/webm" />
        <source src="/img/money/BANNER-PHONE.mp4" type="video/mp4" />
      </video> -->
      <v-img src="/img/money/BANNER-PHONE.gif"></v-img>
    </template>
    <template v-slot:component>
      <v-layout column class="lasbalas-mobile-wrapper" ref="content">
        <v-layout row class="lasbalas-mobile-content">
          <v-layout column class="lb-mobile-sections">
            <v-layout
              column
              class="lb-mobile-camino-container"
              :class="{'lbm-camino-height': $store.getters.getViewCaminoInfo}"
            >
              <v-flex sticky-container class="lb-mobile-camino-presentation" id="first">
                <div class="lbm-camino-header-title">
                  <h1
                    class="text-uppercase lbm-header-text-color"
                  >¿Qué pasa antes de que escuches la canción?</h1>
                </div>
                <v-divider class="lbm-camino-divider"></v-divider>
                <div class="lbm-camino-header-text">
                  <p class="lbm-header-text-color" v-html="header_text"></p>
                </div>
              </v-flex>
              <Camino />
              <CaminoInfo />
            </v-layout>
            <DestinoInfo />
            <ThirdInfo id="third" />
          </v-layout>
          <v-layout column ref="sidebar" class="lbm-sidebar lbm-sidebar-background">
            <v-layout column style="padding-left: 2px">
              <v-icon large color="white" @click="prevInfo">keyboard_arrow_up</v-icon>
              <v-icon large color="white" @click="nextInfo">keyboard_arrow_down</v-icon>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>
    </template>
  </cmp-layout>
</template>
<script>
import ComponentsLayout from "../ComponentsLayout";
import Camino from "../../components/Money/Camino";
import CaminoInfo from "../../components/Money/CaminoInfo";
import DestinoInfo from "../../components/Money/DestinoInfo";
import ThirdInfo from "../../components/Money/ThirdInfo";

export default {
  name: "",
  components: {
    "cmp-layout": ComponentsLayout,
    Camino,
    CaminoInfo,
    DestinoInfo,
    ThirdInfo
  },
  // created () {
  //    window.addEventListener('scroll', this.onScroll)
  // },
  mounted() {
    this.$store.dispatch("getCurrentCamino").then(() => {});
  },
  // `destroy () {
  //     window.removeEventListener('scroll', this.onScroll)
  // },`
  data() {
    return {
      current_info: "#first",
      header_text:
        "Detrás de cada tema hay una industria con artistas, productores musicales y de video, distribuidores, e incluso, espacios de presentación. A veces legal, a veces ilegal crece y mueve millones. Algunos de sus eslabones son imprescindibles, otros no; pero justamente en recorrer cada uno de sus pasos radica el éxito.",
      is_updated: false,
      showToolbarActions: true,
      lastScrollPosition: 0,
      showSidebar: true
    };
  },
  computed: {
    viewCaminoPresentation() {
      if (
        this.$store.getters.getViewCaminoInfo ||
        this.$store.getters.getViewDestinoInfo ||
        this.$store.getters.getViewThirdInfo
      )
        return false;
      else return true;
    },
    viewCaminoInfo() {
      if (
        this.$store.getters.getViewDestinoInfo ||
        this.$store.getters.getViewThirdInfo
      ) {
        return false;
      } else return true;
    },
    viewCaminoToolbar() {
      if (
        this.showToolbarActions &&
        !this.$store.getters.getViewDestinoInfo &&
        !this.$store.getters.getViewThirdInfo
      )
        return true;
      else return false;
    },
    viewLasBalasSidebar() {
      if (
        (this.$store.getters.getViewCaminoInfo ||
          this.$store.getters.getViewDestinoInfo ||
          this.$store.getters.getViewThirdInfo) &&
        this.showToolbarActions
      )
        return true;
      else return false;
    }
  },
  methods: {
    onScroll(e) {
      const scroll = document.scrollingElement.scrollTop;
      const first = document.getElementById("first");
      const second = document.getElementById("second");
      const third = document.getElementById("third");
      if (scroll >= third.offsetTop) {
        this.current_info = "#third";
      } else if (scroll >= second.offsetTop) {
        this.current_info = "#second";
      } else if (scroll >= first.offsetTop) {
        this.current_info = "#first";
      }

      const { content, sidebar } = this.$refs;
      const off =
        e.target.scrollingElement.scrollTop - content.offsetHeight - 8;
      if (off > 0) {
        sidebar.style.transform = `translateY(-${off}px)`;
      } else {
        sidebar.style.transform = null;
      }
      // const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;

      // if (this.$store.getters.getViewCaminoInfo || this.$store.getters.getViewDestinoInfo || this.$store.getters.getViewThirdInfo) {
      //   if (currentScrollPosition < 0) {
      //       return
      //   }
      //   if (Math.abs(currentScrollPosition - this.lastScrollPosition) < 60) {
      //       return
      //   }
      //   // Here we determine whether we need to show or hide the navbar
      //   this.showToolbarActions = currentScrollPosition < this.lastScrollPosition;
      //   this.lastScrollPosition = currentScrollPosition;
      // }
    },
    nextInfo() {
      switch (this.current_info) {
        case "#first":
          this.current_info = "#second";
          break;
        case "#second":
          this.current_info = "#third";
          break;
        case "#third":
          return;
      }
      this.$vuetify.goTo(this.current_info, { duration: 0 });
      // if (this.$store.getters.getViewCaminoInfo) {
      //    this.$store.commit('setViewCaminoInfo', false);
      //    this.$store.commit('setViewDestinoInfo', true);
      // }
      // else if (this.$store.getters.getViewDestinoInfo) {
      //    this.$store.commit('setViewDestinoInfo', false);
      //    this.$store.commit('setViewThirdInfo', true);
      // }
      // else if (this.$store.getters.getViewThirdInfo) {
      //    this.$store.commit('setViewCaminoInfo', true);
      //    this.$store.commit('setViewThirdInfo', false);
      // }
    },
    prevInfo() {
      switch (this.current_info) {
        case "#second":
          this.current_info = "#first";
          break;
        case "#third":
          this.current_info = "#second";
          break;
        case "#first":
          return;
      }
      this.$vuetify.goTo(this.current_info, { duration: 0 });
      // if (this.$store.getters.getViewCaminoInfo) {
      //    this.$store.commit('setViewCaminoInfo', false);
      // }
      // else if (this.$store.getters.getViewDestinoInfo) {
      //    this.$store.commit('setViewDestinoInfo', false);
      //    this.$store.commit('setViewCaminoInfo', true);
      // }
      // else if (this.$store.getters.getViewThirdInfo) {
      //    this.$store.commit('setViewDestinoInfo', true);
      //    this.$store.commit('setViewThirdInfo', false);
      // }
    }
  }
};
</script>
<style lang="scss" scoped>
.lasbalas-mobile-wrapper {
  width: 100%;
  overflow-x: hidden;
}

.lb-mobile-camino-container {
  background: url(/img/money/mobile/camino-bkg.jpg) no-repeat fixed;
  background-size: cover;
  min-width: 100vw;
}

.lbm-camino-toolbar-with {
  width: 85vw;
}

.lbm-header-text-color {
  color: white;
}

.lbm-camino-header-title {
  font-family: Andada-Bold, Roboto, sans-serif;
  letter-spacing: 5px;
  font-size: 2vw;
}

.lbm-camino-header-text {
  font-family: Andada;
}

.lb-mobile-camino-presentation {
  background-color: rgba(0, 0, 0, 0.68);
  padding: 10vw 28px 10vw 16px;
  width: 81vw;
}

.lbm-camino-divider {
  padding-top: 2vw;
  margin: 4vw 6vw 6vw 0;
  background-color: #e5dc16;
}

.lbm-sidebar {
  width: 19vw;
  // height: 20vw;
  position: fixed;
  right: 0;
  // top: 47.5vw;
  padding: 8px 5px;
  z-index: 2;
  bottom: 0;
}

.lbm-sidebar-background {
  background-color: rgba(0, 0, 0, 0.91);
}

.lbm-toolbar-transition {
  transform: translate3d(0, -100%, 0) !important;
}

.lbm-toolbar {
  transform: translate3d(0, 0, 0) !important;
  transition: 0.1s all ease-out;
}
</style>
