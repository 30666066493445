<template>
  <article class="destino-scrolly-container black">
    <v-layout row>
      <v-spacer></v-spacer>
      <v-flex xs10>
        <v-layout align-center class="lasbalas-destino-header white--text">
          <v-flex xs5 class="pa-4 destino-header-text-container text-body">
            Todo el dinero que mueve la industria, en última instancia, es pagado por los consumidores. Los fanes
            del reguetón pagan, por diferentes vías, gran cantidad de dinero y que no es únicamente para el artista,
            muchos se benefician de ello.
          </v-flex>
          <v-spacer class="destino-header-spacer lasbalas-header-spacer"></v-spacer>
          <v-flex
            xs6
            class="lasbalas-destino-header-title text-xs-center title-font text-uppercase destino-header"
          >¿A dónde va tu dinero?</v-flex>
        </v-layout>
        <!-- <section > -->
        <v-layout id="scrolly-destino" class="demowrap px-4">
          <v-flex xs6 id="imagesDestinoContainer">
            <!-- <div class="img-panel-destino destino4"> -->
            <v-img
              src="img/money/destino/destino-4.jpg"
              class="destino-img"
              height="500"
              v-if="current === 3"
            />
            <!-- </div> -->

            <!-- <div class="img-panel-destino destino3"> -->
            <v-img
              src="img/money/destino/destino-3.jpg"
              class="destino-img"
              height="500"
              v-if="current === 2"
            />
            <!-- </div> -->

            <!-- <div class="img-panel-destino destino2"> -->

            <v-img
              src="img/money/destino/destino-2.jpg"
              class="destino-img"
              height="500"
              v-if="current === 1"
            />
            <!-- </div> -->

            <!-- <div class="img-panel-destino destino1"> -->
            <v-img
              src="img/money/destino/destino-1.jpg"
              class="destino-img"
              height="500"
              v-if="current === 0"
            />
            <!-- </div> -->
          </v-flex>
          <v-flex xs6>
            <v-layout column fill-height class="destino-info-container mt-4">
              <div
                id="destinoScrollContainer"
                class="destinoScrollContainer black white--text pa-5"
                ref="destinoScrollContainer"
                style="max-height: 300px;"
                v-bar
                v-scroll:#scroll-target-destino="onScroll"
              >
                <!-- <vue-scroll :ops="opsDestino" ref="destinoScrollContainer"> -->
                <div id="scroll-target-destino">
                  <div class="text-body destino-step destino-text1" v-html="destinoText[0].text"></div>
                  <div class="destino-separator"></div>
                  <div class="text-body destino-step destino-text2" v-html="destinoText[1].text"></div>
                  <div class="destino-separator"></div>
                  <div class="text-body destino-step destino-text3" v-html="destinoText[2].text"></div>
                  <div class="destino-separator"></div>
                  <div class="text-body destino-step destino-text4" v-html="destinoText[3].text"></div>
                </div>
                <!-- </vue-scroll> -->
              </div>
              <div class="destino-text-footer black white--text">
                <div class="destino-text-inner-container mx-3 pa-3">
                  <span class="destino-text-footer-number">+ 1 648 500 CUC</span>
                  <v-spacer class="destino-text-footer-spacer"></v-spacer>
                  <span class="destino-text-footer-info">movió la industria del reguetón en 2018.</span>
                </div>
              </div>
            </v-layout>
          </v-flex>
        </v-layout>
        <!-- </section> -->
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
  </article>
</template>

<script>
// import vuescroll from "vuescroll";

export default {
  name: "LasBalasSeccionDestino",
  components: {
    // vuescroll
  },
  data() {
    return {
      current: 0,
      scroller_destino: null,
      scrolly_destino: null,
      figure_destino: null,
      article_destino: null,
      step_destino: null,
      opsDestino: {
        vuescroll: {
          wheelScrollDuration: 900
        },
        scrollPanel: {
          speed: 1500
        },
        rail: {},
        bar: {
          background: "#fff"
        }
      },
      destinoText: [
        {
          text:
            "Aunque para escuchar una canción de reguetón en Cuba no es necesario comprarla, de tu bolsillo sí sale el dinero que alimenta la industria. Por ejemplo, pagas por el Paquete semanal y ahí encuentras la música actual y los éxitos más antiguos, aunque no se pueden calcular con exactitud sus ingresos." +
            "<br><br>También puedes escucharla, o verla, en los medios de comunicación tradicionales como la televisión y la radio. Esto último no lo pagas directamente, pero algunas plataformas como Youtube generan pagos por vistas y les da visibilidad a los artistas, clave para alcanzar la popularidad. Lo que te lleva a la principal forma de pago: los conciertos." +
            "<br><br>Mientras más “pegao” esté el artista, de su fama y del lugar, más caro vale un concierto. Solo en 2018 nuestro equipo contabilizó 109 conciertos de 11 artistas o grupos en 10 centros nocturnos estatales y privados en La Habana y 2 más en Cienfuegos y Pinar del Río con una recaudación mínima estimada, según el precio de entrada y la capacidad del lugar, de más de 723 500 CUC. Y eso solo en Cuba.",
          index: 1
        },
        {
          text:
            "Para que un artista en Cuba pueda dar un concierto previamente debe concertarse un contrato entre el centro cultural y la agencia que representa al artista. Es un procedimiento requerido, de lo contrario las presentaciones son ilegales o tienen que hacerse por invitaciones a través de terceros o como artistas aficionados. Por esta intermediación la agencia de representación puede cobrar hasta un 30% del valor acordado." +
            "<br><br>Por tanto, la ganancia del artista varía según los distintos mecanismos; los más establecidos suelen presentarse por un precio fijo previamente pactado o según los ingresos obtenidos en taquilla." +
            "<br><br>Con respecto a los medios de comunicación, son muy pocos aún los que reciben dinero desde plataformas de Internet como Youtube, sobre todo porque para el contexto cubano eso es muy difícil y es necesario acceder a alguna cuenta fuera del país que pueda recibir el dinero; pero algunos lo han conseguido.",
          index: 2
        },
        {
          text:
            "Del dinero recibido por el artista debe pagarle a su equipo de trabajo y cumplir con los impuestos sobre los ingresos personales que se calculan a partir de los ingresos obtenidos, las retenciones y los gastos incurridos en la actividad artística hasta 50%." +
            "<br><br>Además, deben pagar la producción de nuevas canciones si quieren mantenerse en la escena, el ciclo nunca para." +
            "<br><br>Para producir una canción los estudios cobran dependiendo de su posición, su legitimidad y el tipo de música que graben. Los precios oscilan entre 3 y 400 CUC por canción, aunque pudiera ser más si intervienen estudios extranjeros." +
            "<br><br>Según datos de los estudios, se graban entre 10 y 12 canciones semanales. Calculando a 10 canciones semanales, son más de 500 canciones anuales producidas. Se identificaron al menos 40 estudios que graban reguetón cubano. De ellos 10 de reguetón y 15 de reparto. Si calculamos al precio mínimo en cada caso de 5 CUC y 80 CUC  tenemos un estimado de 437 500 CUC en solo un año." +
            "<br><br>Además, las canciones necesitan de un video clip para llegar a más personas y dependiendo de la complejidad de la producción los precios varían desde 80 a 900 CUC en las canciones de Reparto y entre 150 y 10 000 CUC en las canciones de Farándula." +
            "<br><br>Solo siete artistas durante 2018 (Divan, Yomil y el Dany, El Taiger, Dana, Alex Duval, la señorita Dayana y Jacob Forever) tienen 23 videos clip, calculado a 5 000 CUC y 10 000 para Yomil y el Dany, según sus propias declaraciones nos da un mínimo de 125 000 CUC.",
          index: 3
        },
        {
          text:
            "Por último, las canciones y los videos llegan al Paquete y a los medios de comunicación donde pueden ser consumidas por los fanes." +
            "<br><br>Son los propios estudios y muchas veces los realizadores y los promotores (que pueden ser parte del estudio o contratados de forma independiente por el artista) quienes se ocupan de la promoción y distribución de la música." +
            "<br><br>Este paso, aunque no tiene altos costos, es esencial para lograr una buena relación entre el artista y el público." +
            "<br><br>Y así comienza nuevamente el ciclo por donde te llega la música.",
          index: 4
        }
      ]
    };
  },
  mounted() {
    // // build scene
    // this.$scrollmagic.attachTo(this.$refs.destinoScrollContainer);
    // const sceneText = this.$scrollmagic
    //   .scene({
    //     triggerElement: "#destinoTrigger1",
    //     triggerHook: 5,
    //     duration: 800
    //   })
    //   .setTween(
    //     TweenMax.to("#destinoScrollContainer .destino-text1", 1, {
    //       opacity: 0,
    //       ease: Linear.easeInOut
    //     })
    //   );
    // this.$refs.destinoScrollContainer.$scrollmagic.addScene(
    //   this.sceneEsquemaUno
    // );
    // this.$refs.destinoScrollContainer.$scrollmagic.addScene(
    //   this.sceneEsquemaDos
    // );
    // this.$refs.destinoScrollContainer.$scrollmagic.addScene(
    //   this.sceneEsquemaTres
    // );
    // this.$refs.destinoScrollContainer.$scrollmagic.addScene(
    //   this.sceneEsquemaCuatro
    // );
  },
  computed: {
    // sceneEsquemaUno() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#destinoTrigger1",
    //       triggerHook: "onEnter",
    //       duration: "100%"
    //     })
    //     .setTween(
    //       TweenMax.to("#imagesDestinoContainer .destino1", 5, {
    //         opacity: 0,
    //         ease: Linear.easeInOut
    //       })
    //     );
    // },
    // sceneEsquemaDos() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#destinoTrigger2",
    //       triggerHook: "onEnter",
    //       duration: "100%"
    //     })
    //     .setTween(
    //       TweenMax.to("#imagesDestinoContainer .destino2", 1, {
    //         opacity: 0,
    //         ease: SlowMo.ease.config(0.5, 0.8, false)
    //       })
    //     );
    // },
    // sceneEsquemaTres() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#destinoTrigger3",
    //       triggerHook: "onEnter",
    //       duration: "100%"
    //     })
    //     .setTween(
    //       TweenMax.to("#imagesDestinoContainer .destino3", {
    //         opacity: 0,
    //         ease: SlowMo.ease.config(0.5, 0.8, false)
    //       })
    //     );
    // },
    // sceneEsquemaCuatro() {
    //   return this.$scrollmagic
    //     .scene({
    //       triggerElement: "#destinoTrigger4",
    //       triggerHook: "onEnter",
    //       duration: "75%"
    //     })
    //     .setTween(
    //       TweenMax.to("#imagesDestinoContainer .destino4", {
    //         opacity: 0,
    //         ease: SlowMo.ease.config(0.5, 0.8, false)
    //       })
    //     );
    // }
  },
  methods: {
    onScroll(e) {
      const scroll = e.target.scrollTop;
      const seps = document.querySelectorAll(".destino-separator");
      for (let i = 0; i < seps.length; i++) {
        if (scroll + 60 < seps[i].offsetTop) {
          this.current = i;
          return;
        }
      }
      this.current = 3;
    }
  }
};
</script>

<style scoped>
.destino-separator {
  height: 3px;
  width: 70%;
  background-color: white;
  margin: 60px auto;
}

.destino-step {
  min-height: 100%;
}

#scrolly-destino {
  /* position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 110px 0 90px; */
  background: url("/img/money/money-destino-bkg.jpg") repeat-y;
  background-size: cover;
  /* padding: 1rem;
  height: 82vh; */
}
/* #scrolly-destino > * {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
} */

/* article.destino-scrolly-container {
        background-color: black;
        height: 100vh;
    } */

/* article.destinos {
  position: relative;
  padding: 0 1rem 0 0;
  max-width: 60rem;
} */

/* .destino-step {
  padding: 2vw 5vw 0;
} */

/* .img-panel-destino {
  position: absolute;
  left: 18px;
  top: 0;
} */

/* .destino-header {
  width: 50vw;
  padding-top: 45px;
  margin-left: 0;
  text-align: left;
  padding-left: 20px;
} */

/* .destino-header-text-container {
      width: 52vw;
      padding-top: 20px;
      margin: 0 5vw 2vh 7vw;
    } */

.destinoheader-text {
  font-weight: normal;
  margin: 8px;
  letter-spacing: 2px;
}

.destino-header-spacer {
  margin-top: 20px;
  height: 68px;
  position: relative;
  right: 16px;
}

/* .destino-img {
  width: 45vw;
  align-self: center;
  margin: 0;
} */

/* .destino-info-container {
  max-width: 38vw;
  position: relative;
  left: 45.4vw;
} */

/* .destinoScrollContainer {
  background-color: black;
  color: #fff;
  width: 38vw;
  height: 28vw;
  position: relative;
  right: 0;
  top: 6px;
  padding-bottom: 3vw;
} */

/* .destino-text-footer {
  height: 6vw;
  width: 100%;
  background-color: black;
  color: white;
  padding: 0 8px;
  margin-top: 6px;
} */

.destino-text-inner-container {
  border-top: solid 1px white;
  text-align: center;
  /* padding-top: 5px; */
}

.destino-text-footer-number {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 4px;
  align-self: center;
}

.destino-text-footer-info {
  font-style: italic;
}

/* .lasbalas-destino-header {
        text-align: center;
        padding-top: 2vh;
        color: white;
    } */

.lasbalas-destino-header-title {
  font-weight: bold;
  font-size: 18px;
  letter-spacing: 8px;
}
</style>
